import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ListMobileStyles } from './ListMobileStyles';
import { ApiUser } from '../../../types/user.types';

import { format } from 'date-fns';
import Menu from '../menu/Menu';

interface ListMobileProps {
  users: ApiUser[];
  handleDelete(user: ApiUser): void;
  handleBlock(user: ApiUser): void;
  handleRatingModal(user: ApiUser): void;
}

function ListMobile({
  users,
  handleDelete,
  handleBlock,
  handleRatingModal,
}: ListMobileProps) {
  return (
    <ListMobileStyles>
      <ul>
        {users.map((user) => (
          <li key={user.id}>
            <div className="infoWrapper">
              <span className="textBase16 fontSemiBold">Действия</span>
              <span className="textBase16">
                <Menu
                  handleDelete={handleDelete}
                  handleBlock={handleBlock}
                  handleRatingModal={handleRatingModal}
                  user={user}
                />
              </span>
            </div>

            <div className="infoWrapper">
              <span className="textBase16 fontSemiBold">Nickname</span>
              <span className="textBase16">{user.login}</span>
            </div>

            <div className="infoWrapper">
              <span className="textBase16 fontSemiBold">Рейтинг</span>
              <span className="textBase16">{user.rating}</span>
            </div>

            <div className="infoWrapper">
              <span className="textBase16 fontSemiBold">Заблокирован</span>
              <span className="textBase16">{user.blocked ? 'Да' : 'Нет'}</span>
            </div>

            {user.roles && user.roles[0] && user.roles[0].name && (
              <div className="infoWrapper">
                <span className="textBase16 fontSemiBold">Роль</span>
                <span className="textBase16">{user.roles[0].name}</span>
              </div>
            )}

            <div className="infoWrapper">
              <span className="textBase16 fontSemiBold">Дата регистрации</span>
              <span className="textBase16">{format(new Date(user.created_at), 'dd.MM.yyyy')}</span>
            </div>
          </li>
        ))}
      </ul>
    </ListMobileStyles>
  );
}

export default ListMobile;
