import * as React from 'react';

export const TreasuresMapIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M14.368 2.157a2 2 0 0 1 1.265 0l5 1.666A2 2 0 0 1 22 5.721v13.892a2 2 0 0 1-2.633 1.897L15 20.054l-5.368 1.79a2 2 0 0 1-1.264 0l-5-1.667A2 2 0 0 1 2 18.279V4.387A2 2 0 0 1 4.632 2.49L9 3.946zM15 4.054l-5.368 1.79a2 2 0 0 1-1.264 0L4 4.386V18.28l5 1.667 5.368-1.79a2 2 0 0 1 1.265 0L20 19.614V5.72zm-1.707 4.239a1 1 0 0 1 1.414 0l.793.793.793-.793a1 1 0 1 1 1.414 1.414l-.793.793.793.793a1 1 0 0 1-1.414 1.414l-.793-.793-.793.793a1 1 0 0 1-1.414-1.414l.793-.793-.793-.793a1 1 0 0 1 0-1.414M6 16a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0m2 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
      clipRule="evenodd"
    />
  </svg>
);
