import React, { useCallback, useState } from 'react';
import { FormikProvider } from 'formik';
import Button from '../../components/buttons/button/Button';
import MapPreview from '../../components/features/treasuresMap/tabs/mapPreview/MapPreview';
import TreasuresMapStyles from './TreasuresMapStyles';
import TabsHandler from '../../components/ui/tabsHandler/TabsHandler';
import { Link, useSearchParams } from 'react-router-dom';
import Prizes from '../../components/features/treasuresMap/tabs/prizes/Prizes';
import Rules from '../../components/features/treasuresMap/tabs/rules/Rules';
import Winners from '../../components/features/treasuresMap/tabs/winners/Winners';
import Posters from '../../components/features/treasuresMap/tabs/posters/Posters';

const availableTabsNamesArr = ['map', 'prizes', 'rules', 'winners'] as const;

export type AvailableTreasuresMapTabs = typeof availableTabsNamesArr[number];

const tabsHandlerButtons: {
  value: AvailableTreasuresMapTabs;
  name: string;
}[] = ([
  {
    name: 'Карта',
    value: 'map',
  },
  {
    name: 'Призы',
    value: 'prizes',
  },
  {
    name: 'Правила',
    value: 'rules',
  },
  {
    name: 'Победители',
    value: 'winners',
  },
]);

function TreasuresMap() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<AvailableTreasuresMapTabs>(() => {
    const tabFromParams = searchParams.get('tab');

    if (tabFromParams && availableTabsNamesArr.includes(tabFromParams as AvailableTreasuresMapTabs)) {
      return tabFromParams as AvailableTreasuresMapTabs;
    }

    return 'map';
  });

  const handleChangeActiveTab = useCallback((name: AvailableTreasuresMapTabs) => {
    setActiveTab(name);
  }, []);

  return (
    <TreasuresMapStyles>
      <div className="head">
        <h1 className="textBold28">Карта сокровищ</h1>

        {activeTab === 'map' && (
          <Link to="/games/treasures-map/create-poster">
            <Button
              text="Добавить изображение на карту"
              onClick={() => {}}
            />
          </Link>
        )}

        {activeTab === 'prizes' && (
          <Link to="/games/treasures-map/prizes">
            <Button
              text="Добавить приз"
              onClick={() => {}}
            />
          </Link>
        )}
      </div>

      <TabsHandler <AvailableTreasuresMapTabs>
        buttons={tabsHandlerButtons}
        handleChangeActiveTab={handleChangeActiveTab}
        selectedTab={activeTab}
      />

      {activeTab === 'map' && (
        <Posters />
      )}

      {activeTab === 'prizes' && (
        <Prizes />
      )}

      {activeTab === 'rules' && (
        <Rules />
      )}

      {activeTab === 'winners' && (
        <Winners />
      )}
    </TreasuresMapStyles>
  );
}

export default TreasuresMap;
