import styled from 'styled-components';

const PostersStyles = styled.div`
  .posters {
    display: flex;
    justify-content: center;
    gap: 24px;
    flex-wrap: wrap;
    
    @media (min-width: 1024px) {
      justify-content: flex-start;
    }
  }
`;

export default PostersStyles;
