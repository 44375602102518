import React, { useState } from 'react';
import { LoginStyles } from './LoginStyles';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../state/hooks';
import { loginUser } from '../../state/slices/userSlice';
import InputField from '../../components/form/input/InputField';
import Button from '../../components/buttons/button/Button';
import Logo from '../../components/other/logo/Logo';

function Login() {
  const dispatch = useAppDispatch();

  const [submittingError, setSubmittingError] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    onSubmit: async (values) => {
      if (submittingError) {
        setSubmittingError('');
      }

      if (values) {
        setSubmitting(true);

        await dispatch(loginUser(values)).unwrap().then((res) => {
          if (res.statusCode < 200 && res.statusCode >= 300) {
            // @ts-ignore
            const error = (res.data as {message: string})?.message || 'Unknown error occurred';

            setSubmittingError(error);
          }

          setSubmitting(false);
        }).catch((e) => {
          console.error(e);
          setSubmittingError('Произошла ошибка на сервере');
        });

        setSubmitting(false);
      }
    },
    validationSchema: Yup.object().shape({
      login: Yup.string().required('Обязательное поле'),
      password: Yup.string().required('Обязательное поле'),
    }),
  });

  return (
    <LoginStyles className="container">
      <FormikProvider value={formik}>
        <div className="content">
          <Logo />

          <div className="form">
            <h1 className="textBold28 title">Вход</h1>

            <form className="fields">
              <InputField
                placeholder="Введите login"
                name="login"
                onChange={formik.handleChange}
                value={formik.values.login}
                label="Login"
              />

              <InputField
                placeholder="Введите пароль"
                name="password"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                label="Пароль"
                autoComplete="current-password"
              />
            </form>

            <div className="buttonWrapper">
              <Button
                text="Войти"
                onClick={formik.handleSubmit}
                fullWidth
                disabled={submitting}
                isLoading={submitting}
              />
              {submittingError && <span className="textBase12 errorText">{submittingError}</span>}
            </div>
          </div>

          <div />
        </div>
      </FormikProvider>
    </LoginStyles>
  );
}

export default Login;
