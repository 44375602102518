import React from 'react';

function ArrowRightIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path d="M4.58332 11H17.4167" stroke="#CB1F1F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 17.4173L17.4167 11.0007L11 4.58398" stroke="#CB1F1F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default ArrowRightIcon;
