import React, { useState } from 'react';
import ModalConfirmDecline from '../../../../../../modals/modalConfirmDecline/ModalConfirmDecline';
import { notification } from '../../../../../../../helpers/notifications/toastify';
import workWithResponse from '../../../../../../../helpers/workWithResponse';
import { Api } from '../../../../../../../api';
import { TApiPrize } from '../../../../../../../types/treasureMap.types';

interface DeleteModalProps {
  handleClose: () => void;
  onPrizeDelete: (prize: TApiPrize) => void;
  prize: TApiPrize;
}

function DeleteModal({ handleClose, prize, onPrizeDelete }: DeleteModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const deleteUser = async () => {
    try {
      setIsLoading(true);

      await workWithResponse(() => Api.deletePrize(prize.ID)).then((res) => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          setIsLoading(false);
          handleClose();
          onPrizeDelete(prize);
        }
      });
    } catch (e) {
      console.error(e);
      notification.error('Произошла ошибка при удалении');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalConfirmDecline
      onClose={() => (isLoading ? () => null : handleClose())}
      title="Удалить приз?"
      buttons={
        [
          {
            text: 'Отмена',
            onClick: () => {
              handleClose();
            },
            theme: 'black',
            isLoading,
          },
          {
            text: 'Удалить',
            onClick: () => {
              deleteUser();
            },
            theme: 'red',
            isLoading,
          },
        ]
      }
    />
  );
}

export default DeleteModal;
