import React from 'react';

function CloseMenuIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22.6 9.4L9.4 22.6M9.4 9.4l13.2 13.2"
      />
    </svg>
  );
}

export default CloseMenuIcon;
