import styled from 'styled-components';

export const ListMobileStyles = styled.div`
  padding-bottom: 40px;
  
  ul {
    margin-bottom: 20px;
  }
  
  li {
    border-radius: 4px;
    border: 1px solid var(--Stroke2, #393636);
    background: var(--Cards2, #1C1C1C);
    
    box-sizing: border-box;
    
    a {
      color: var(--grey-8, #1C1C1C);
    }
    
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    
    .headContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
    }
    
    .headButtons {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    
    .infoWrapper {
      display: flex;
      justify-content: space-between;
      
      padding: 12px;
      
      &:not(:last-child) {
        border-bottom: 1px solid var(--Stroke2, #393636);
      }
    }
  }
`;
