import { TEmojiItem } from '../../../types/emoji.types';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { forwardRef, useRef } from 'react';
import EmojiPickerStyles from './EmojiPickerStyles';

const emojis = [
  {
    title: 'Лицевые эмоции',
    emojis: [
      { name: 'Смех', emoji: '😀' },
      { name: 'Смех с открытым ртом', emoji: '😁' },
      { name: 'Слёзы радости', emoji: '😂' },
      { name: 'Глаза с сердечками', emoji: '😍' },
      { name: 'Поцелуй', emoji: '😘' },
      { name: 'Подмигивание', emoji: '😉' },
      { name: 'Грустное лицо', emoji: '☹️' },
      { name: 'Плачущие глаза', emoji: '😢' },
      { name: 'Злой смайлик', emoji: '😡' },
      { name: 'Звезды в глазах', emoji: '🤩' },
      { name: 'Богач', emoji: '🤑' },
      { name: 'Череп', emoji: '💀' },
      { name: 'Череп 2', emoji: '☠️' },
      { name: 'Дьявол', emoji: '😈' },
      { name: 'Пришелец', emoji: '👽' },
      { name: 'Чудище', emoji: '👾' },
    ],
  },
  {
    title: 'Животные',
    emojis: [
      { name: 'Лицо кошки', emoji: '😸' },
      { name: 'Лицо собаки', emoji: '🐶' },
      { name: 'Тигр', emoji: '🐯' },
      { name: 'Кролик', emoji: '🐰' },
      { name: 'Слон', emoji: '🐘' },
      { name: 'Лев', emoji: '🦁' },
      { name: 'Лицо обезьяны', emoji: '🐵' },
      { name: 'Лошадь', emoji: '🐴' },
    ],
  },
  {
    title: 'Еда',
    emojis: [
      { name: 'Пицца', emoji: '🍕' },
      { name: 'Бургер', emoji: '🍔' },
      { name: 'Хот-дог', emoji: '🌭' },
      { name: 'Яблоко', emoji: '🍎' },
      { name: 'Торт', emoji: '🍰' },
      { name: 'Мороженое', emoji: '🍦' },
      { name: 'Суши', emoji: '🍣' },
      { name: 'Тако', emoji: '🌮' },
    ],
  },
  {
    title: 'Спорт',
    emojis: [
      { name: 'Футбольный мяч', emoji: '⚽' },
      { name: 'Баскетбол', emoji: '🏀' },
      { name: 'Теннисный мяч', emoji: '🎾' },
      { name: 'Бейсбол', emoji: '⚾' },
      { name: 'Регби мяч', emoji: '🏉' },
      { name: 'Велосипедист', emoji: '🚴‍♂️' },
      { name: 'Бегун', emoji: '🏃‍♀️' },
      { name: 'Плавец', emoji: '🏊‍♂️' },
    ],
  },
  {
    title: 'Природа',
    emojis: [
      { name: 'Солнце', emoji: '☀️' },
      { name: 'Облако', emoji: '☁️' },
      { name: 'Радуга', emoji: '🌈' },
      { name: 'Дерево', emoji: '🌳' },
      { name: 'Цветок', emoji: '🌸' },
      { name: 'Гора', emoji: '🏔️' },
      { name: 'Океан', emoji: '🌊' },
      { name: 'Снежинка', emoji: '❄️' },
    ],
  },
  {
    title: 'Транспорт',
    emojis: [
      { name: 'Машина', emoji: '🚗' },
      { name: 'Автобус', emoji: '🚍' },
      { name: 'Самолёт', emoji: '✈️' },
      { name: 'Корабль', emoji: '🚢' },
      { name: 'Поезд', emoji: '🚆' },
      { name: 'Велосипед', emoji: '🚲' },
      { name: 'Мотоцикл', emoji: '🏍️' },
      { name: 'Вертолет', emoji: '🚁' },
    ],
  },
  {
    title: 'Объекты',
    emojis: [
      { name: 'Сердце', emoji: '❤️' },
      { name: 'Сердце 2', emoji: '💘' },
      { name: 'Сердце 3', emoji: '💚' },
      { name: 'Звезда', emoji: '⭐' },
      { name: 'Подарок', emoji: '🎁' },
      { name: 'Фотоаппарат', emoji: '📷' },
      { name: 'Ноутбук', emoji: '💻' },
      { name: 'Телефон', emoji: '📱' },
      { name: 'Книги', emoji: '📚' },
      { name: 'Очки', emoji: '👓' },
      { name: 'Долар', emoji: '💲' },
    ],
  },
  {
    title: 'Драгоценные камни',
    emojis: [
      { name: 'Алмаз', emoji: '💎' },
      { name: 'Рубин', emoji: '🟥' },
      { name: 'Изумруд', emoji: '🟩' },
      { name: 'Сапфир', emoji: '🔷' },
      { name: 'Драгоценный камень', emoji: '💍' },
      { name: 'Опал', emoji: '🟪' },
      { name: 'Топаз', emoji: '🔶' },
    ],
  },
  {
    title: 'Другое',
    emojis: [
      { name: 'Копирайт', emoji: '©️' },
      { name: 'Копирайт 2', emoji: '®️' },
      { name: 'Трефы', emoji: '♣️' },
      { name: 'Пики', emoji: '♠️' },
      { name: 'Бубны', emoji: '♦️' },
      { name: 'Червы', emoji: '♥️' },
      { name: 'Сделано', emoji: '✔️' },
      { name: 'Ошибка', emoji: '❌️' },
      { name: 'Радиация', emoji: '☢️' },
      { name: 'Биологическая опасность', emoji: '☣️' },
      { name: 'Таблетка', emoji: '💊' },
    ],
  },
  {
    title: 'Пираты',
    emojis: [
      { name: 'Пиратский флаг', emoji: '🏴‍☠️' },
      { name: 'Попугай', emoji: '🦜' },
      { name: 'Якорь', emoji: '⚓' },
      { name: 'Меч крестоносцев', emoji: '⚔️' },
      { name: 'Остров', emoji: '🏝️' },
      { name: 'Тростник', emoji: '🦯' },
      { name: 'Череп', emoji: '💀' },
      { name: 'Ключ', emoji: '🗝️' },
      { name: 'Мешок с деньгами', emoji: '💰' },
      { name: 'Коробка с призом', emoji: '🎁' },
      { name: 'Компас', emoji: '🧭' },
      { name: 'Диамант', emoji: '💎' },
      { name: 'Рубин', emoji: '🟥' },
      { name: 'Изумруд', emoji: '🟩' },
      { name: 'Драгоценный камень', emoji: '💍' },
      { name: 'Опал', emoji: '🟪' },
      { name: 'Топаз', emoji: '🔶' },
      { name: 'Античная реликвия', emoji: '🏺' },
      { name: 'Щит тамплиеров', emoji: '🛡️' },
    ],
  },
];

interface EmojiPickerProps {
  handleClose(): void;
  onEmojiSelect: (value: TEmojiItem) => void;
}

const EmojiPicker = forwardRef<HTMLButtonElement, EmojiPickerProps>(({ onEmojiSelect, handleClose }, openerButtonRef) => {
  const emojisSelectRef = useRef<null | HTMLDivElement>(null);

  useClickOutside(emojisSelectRef, handleClose, openerButtonRef);

  return (
    <EmojiPickerStyles ref={emojisSelectRef}>
      {emojis.map((emojiPack) => (
        <div className="emojisPack" key={emojiPack.title}>
          <span className="textSemiBold16">{emojiPack.title}</span>

          <ul>
            {emojiPack.emojis.map((emoji) => (
              <li key={emoji.emoji}>
                <button title={emoji.name} type="button" onClick={() => onEmojiSelect(emoji)}>
                  {emoji.emoji}
                </button>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </EmojiPickerStyles>
  );
});

export default EmojiPicker;
