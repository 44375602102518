import React, {
  ChangeEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import Button from '../../components/buttons/button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { UsersStyles } from './UsersStyles';
import { ColumnDef } from '@tanstack/react-table';
import { Api } from '../../api';
import { useWindowSize } from 'usehooks-ts';
import { Table } from '../../components/table/Table';
import Pagination from '../../components/table/pagination/Pagination';
import ListMobile from '../../components/users/listMobile/ListMobile';
import ModalConfirmDecline from '../../components/modals/modalConfirmDecline/ModalConfirmDecline';
import Menu from '../../components/users/menu/Menu';
import workWithResponse from '../../helpers/workWithResponse';
import { notification } from '../../helpers/notifications/toastify';
import { ApiUser } from '../../types/user.types';
import { format } from 'date-fns';
import SearchInput from '../../components/form/searchInput/input/SearchInput';
import { RingLoader } from 'react-spinners';
import MainLoader from '../../components/other/loaders/MainLoader';
import ErrorComponent from '../../components/other/error/ErrorComponent';
import ChangeRatingModal from '../../components/users/modals/changeRatingModal/ChangeRatingModal';

function Users() {
  const [tableData, setTableData] = useState<ApiUser[]>([]);
  const [searchResultTableData, setSearchResultTableData] = useState<ApiUser[] | null>(null);
  const [activePage, setActivePage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [activeModalName, setActiveModalName] = useState<'deleting' | 'blocking' | 'rating'>();
  const [userToEdit, setUserToEdit] = useState<ApiUser | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataLoadingError, setDataLoadingError] = useState<boolean>(false);

  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const navigate = useNavigate();
  const location = useLocation();
  const windowSize = useWindowSize();

  function handleChangeSearchInputValue(inputValue: string) {
    setSearchInputValue(inputValue);

    setActivePage(1);
  }

  function handleRatingModal(user: ApiUser) {
    setUserToEdit(user);

    setActiveModalName('rating');
  }

  function handleDeleteModal(user: ApiUser) {
    setUserToEdit(user);

    setActiveModalName('deleting');
  }

  function handleBlockModal(user: ApiUser) {
    setUserToEdit(user);

    setActiveModalName('blocking');
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageParam = searchParams.get('page');

    if (pageParam && !Number.isNaN(Number(pageParam))) {
      setActivePage(Number(pageParam));
    } else {
      setActivePage(1);
    }
  }, []);

  async function getUsers() {
    setIsLoading(true);

    await workWithResponse(() => Api.getUsers((activePage - 1) * 10, 10)).then((res) => {
      if (res.data && !res.error) {
        setTableData(res.data.data);
        setTotal(res.data.count);
      } else {
        setDataLoadingError(true);
      }
    });

    setIsLoading(false);
  }

  async function deleteUser() {
    if (userToEdit) {
      setIsLoading(true);
      try {
        await workWithResponse(() => Api.deleteUser(userToEdit.id)).then((res) => {
          if (res.error) {
            notification.error('Произошла ошибка при удалении');
          } else {
            getUsers();
          }
        });
      } catch (e) {
        notification.error('Произошла ошибка при удалении');
      }

      setIsLoading(false);
    }
  }

  async function handleBlockUser() {
    if (userToEdit) {
      setIsLoading(true);

      try {
        if (userToEdit.blocked) {
          await workWithResponse(() => Api.unblockUser(userToEdit.id)).then((res) => {
            if (res.error) {
              notification.error('Произошла ошибка при разблокировании');
            } else {
              setTableData(tableData.map((user) => (user.id === userToEdit.id ? { ...user, blocked: false } : user)));
            }
          });
        } else {
          await workWithResponse(() => Api.blockUser(userToEdit.id)).then((res) => {
            if (res.error) {
              notification.error('Произошла ошибка при блокировании');
            } else {
              setTableData(tableData.map((user) => (user.id === userToEdit.id ? { ...user, blocked: true } : user)));
            }
          });
        }
      } catch (e) {
        notification.error(`Произошла ошибка при ${userToEdit!.blocked ? 'разблокировании' : 'блокировании'}`);
      }

      setIsLoading(false);
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams();
    searchParams.set('page', String(activePage));

    if (searchInputValue) {
      searchParams.set('login', searchInputValue);
    }

    navigate({ search: `?${searchParams.toString()}` });

    getUsers();
  }, [activePage, navigate]);

  useEffect(() => {
    let timeoutId: any;

    async function getUsersByLogin() {
      if (searchInputValue.length > 0 && searchResultTableData) {
        setSearchResultTableData(null);
      }

      if (searchInputValue.length > 0) {
        try {
          setIsLoading(true);

          await workWithResponse(() => Api.searchUsersByLogin({ login: searchInputValue })).then((res) => {
            if (res.data) {
              setSearchResultTableData(res.data);
            } else {
              notification.error('Произошла ошибка при поиске пользователей');
            }
          });
        } catch (e) {
          console.error(`Error: ${e}`);
          notification.error('Произошла ошибка при поиске пользователей');
        } finally {
          setIsLoading(false);
        }
      }
    }

    // eslint-disable-next-line prefer-const
    timeoutId = setTimeout(getUsersByLogin, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchInputValue]);

  const cols = useMemo<ColumnDef<ApiUser>[]>(
    () => [
      {
        header: 'Nickname',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => (
          <span className="textBase18">
            {row.cell.row.original.login}
          </span>
        ),
        accessorKey: 'login',
        enableSorting: false,
      },
      {
        header: 'Рейтинг',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => (
          <span className="textBase18">
            {row.cell.row.original.rating}
          </span>
        ),
        accessorKey: 'rating',
        enableSorting: false,
      },
      {
        header: 'Заблокирован',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => (
          <span className="textBase18">
            {row.cell.row.original.blocked ? 'Да' : 'Нет'}
          </span>
        ),
        accessorKey: 'blocked',
        enableSorting: false,
      },
      // {
      //   header: 'Роль',
      //   // eslint-disable-next-line react/no-unstable-nested-components
      //   cell: (row) => (
      //     <span className="textBase18">
      //       {row.cell.row.original.roles[0].name}
      //     </span>
      //   ),
      //   accessorKey: 'roles',
      //   enableSorting: false,
      // },
      {
        header: 'Дата регистрации',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => (
          <span className="textBase18">
            {format(new Date(row.cell.row.original.created_at), 'dd.MM.yyyy')}
          </span>
        ),
        accessorKey: 'roles',
        enableSorting: false,
      },
      {
        header: undefined,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => (
          <Menu
            handleDelete={handleDeleteModal}
            handleBlock={handleBlockModal}
            handleRatingModal={handleRatingModal}
            user={row.cell.row.original}
          />
        ),
        accessorKey: 'menu',
        enableSorting: false,
        size: 80,
      },
    ],
    [tableData],
  );

  const handleUpdateUser = useCallback((updatedUser: ApiUser) => {
    if (searchInputValue.length > 0) {
      setSearchResultTableData((prev) => {
        if (prev) {
          return prev.map((user) => (user.id === updatedUser.id ? updatedUser : user));
        }

        return null;
      });
    }

    setTableData((prev) => prev.map((user) => (user.id === updatedUser.id ? updatedUser : user)));
  }, [searchInputValue]);

  return (
    <UsersStyles>
      {activeModalName === 'rating' && userToEdit && (
        <ChangeRatingModal
          onClose={() => setActiveModalName(undefined)}
          user={userToEdit}
          onSuccess={handleUpdateUser}
        />
      )}

      {activeModalName === 'deleting' && (
        <ModalConfirmDecline
          onClose={() => setActiveModalName(undefined)}
          title="Вы уверены, что хотите удалить этого пользователя?"
          buttons={
            [
              {
                text: 'Удалить',
                onClick: () => {
                  setActiveModalName(undefined);
                  deleteUser();
                },
                theme: 'red',
              },
              {
                text: 'Отмена',
                onClick: () => {
                  setActiveModalName(undefined);
                },
                theme: 'black',
              },
            ]
          }
        />
      )}

      {activeModalName === 'blocking' && (
        <ModalConfirmDecline
          onClose={() => setActiveModalName(undefined)}
          title={`Вы уверены, что хотите ${userToEdit?.blocked ? 'разблокировать' : 'заблокировать'} этого пользователя?`}
          description={userToEdit?.blocked ? '' : 'Блокировка пользователя приведет к ограничению его доступа ко всем функциям системы.'}
          buttons={
            [
              {
                text: userToEdit?.blocked ? 'Разблокировать' : 'Заблокировать',
                onClick: () => {
                  setActiveModalName(undefined);
                  handleBlockUser();
                },
                theme: 'red',
              },
              {
                text: 'Отмена',
                onClick: () => {
                  setActiveModalName(undefined);
                },
                theme: 'black',
              },
            ]
          }
        />
      )}

      {dataLoadingError ? <ErrorComponent /> : (
        <>
          <div className="head">
            <h1 className="textBold28">Пользователи</h1>

            <SearchInput
              name="search"
              onChange={handleChangeSearchInputValue}
              placeholder="Поиск"
              borderColors="redBorder"
            />
          </div>

          {isLoading ? <div className="loaderContainer"><MainLoader /></div> : (
            <div className="tableWrapper">
              {
                tableData && windowSize.width > 600 && (
                  <Table
                    data={searchInputValue && searchResultTableData ? searchResultTableData : tableData}
                    columns={cols}
                    withSorting={false}
                  />
                )
              }

              {
                windowSize.width <= 600 && (
                  <ListMobile
                    users={searchInputValue && searchResultTableData ? searchResultTableData : tableData}
                    handleDelete={handleDeleteModal}
                    handleBlock={handleBlockModal}
                    handleRatingModal={handleRatingModal}
                  />
                )
              }
              {total > 0 && (
                <Pagination
                  activePage={activePage}
                  total={total}
                  setActivePage={(pageNumber) => setActivePage(pageNumber)}
                  extraClasses={windowSize.width <= 600 ? 'paginationMobile' : undefined}
                />
              )}
            </div>
          )}
        </>
      )}

    </UsersStyles>
  );
}

export default Users;
