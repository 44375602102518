import styled from 'styled-components';

export const PaginationStyles = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  
  justify-content: flex-end;
  
  overflow: hidden;
  
  &.paginationMobile {
    justify-content: center;
  }
  
  .button, .activePageText {
    padding: 6px 6px;
    
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    
    @media (min-width: 768px) {
      padding: 6px 8px;
      
      font-size: 14px;
    }
  }
  
  .activePageText {
    display: flex;
    flex-direction: column;
    
    border-radius: var(--corners_btn, 4px);
    color: var(--Red-2, #CB1F1F);
    border: 1px solid var(--Red-2, #CB1F1F);
    text-align: center;
    
    @media (min-width: 768px) {
      flex-direction: row;
      gap: 4px;
    }
  }
  
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    &:disabled {
      cursor: not-allowed;
    }
    
    &:nth-child(2) {
      svg {
        transform: rotate(180deg);
      }
    }

    svg {
      path {
        transition: 0.3s ease all;
      }
    }

    &.grey {
      color: var(--grey-6, #595959);

      border: 1px solid #EFEFEF;
      
      &:hover {
        border: 1px solid var(--grey-6, #595959);
      }

      &.loading {
        color: var(--grey-5, #8C8C8C);

        border: 1px solid var(--grey-5, #8C8C8C);
      }

      &:focus {
        color: var(--grey-5, #8C8C8C);

        outline: 2px solid var(--grey-6, #595959);
      }

      &:disabled {
        border: 1px solid #E6E6E6;
        color: #A9A9A9;
        
        svg {
          path {
            stroke: #8C8C8C;
          }
        }
      }
    }
  }
`;
