import styled from 'styled-components';

const CreatePosterStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  
  width: 100%;
  overflow: auto;

  .loaderWrapper {
    height: 1000px;
  }

  .map {
    position: relative;
    display: flex;
    flex-direction: column;

    width: 1000px;
    height: 1000px;

    border-radius: 4px;

    background-position: center;
    background-size: cover;
    
    padding-left: 20px;
    padding-top: 20px;
    
    overflow: hidden;
    
    .mapImage {
      position: absolute;
      top: 20px;
      left: 20px;
    }

    .row {
      position: relative;
      
      display: flex;
      align-items: center;

      height: 10px;

      &::before {
        content: attr(data-row);
        position: absolute;
        left: -20px;
        font-size: 7px;
        color: var(--white);
        width: 15px;
        text-align: right;
        line-height: 10px;
      }
    }
    
    .cellButton {
      position: relative;
      
      border-right: 0.2px solid var(--red-1);
      border-bottom: 0.2px solid var(--red-1);
      border-top: 0.2px solid transparent;
      border-left: 0.2px solid transparent;
      
      transition: .2s ease background-color;

      &.firstRow {
        &::before {
          content: attr(data-col);
          position: absolute;
          top: -15px;
          font-size: 7px;
          transform: translateX(-5px);
          color: var(--white);
          text-align: center;
        }
      }
      
      &:hover {
        border: 0.2px solid var(--red-3);
      }
      
      &.selected {
        background-color: var(--white);
      }
    }
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    
    .formButtonsWrapper {
      max-width: 1020px;
    }
  }
`;

export default CreatePosterStyles;
