import React from 'react';
import { ListMobileStyles } from './ListMobileStyles';
import { TPrize, TWinner } from '../../../../../../types/treasureMap.types';
import { format } from 'date-fns';

interface ListMobileProps {
  winners: TWinner[];
}

function ListMobile({
  winners,
}: ListMobileProps) {
  return (
    <ListMobileStyles>
      <ul>
        {winners.map((winner, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>
            <div className="infoWrapper">
              <span className="textBase16 fontSemiBold">Пользователь</span>
              <span className="textBase16">{winner.user.login}</span>
            </div>
            <div className="infoWrapper">
              <span className="textBase16 fontSemiBold">Приз</span>
              <span className="textBase16">{winner.prize}</span>
            </div>
            <div className="infoWrapper">
              <span className="textBase16 fontSemiBold">Дата выиграша</span>
              <span className="textBase16">{winner.created_at ? format(new Date(winner.created_at), 'dd.MM.yyyy HH:mm') : 'Не известно'}</span>
            </div>
          </li>
        ))}
      </ul>
    </ListMobileStyles>
  );
}

export default ListMobile;
