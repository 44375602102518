import React, {
  ChangeEvent, useEffect, useMemo, useState,
} from 'react';
import { useWindowSize } from 'usehooks-ts';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import workWithResponse from '../../../../../helpers/workWithResponse';
import { Api } from '../../../../../api';
import { Table } from '../../../../table/Table';
import MainLoader from '../../../../other/loaders/MainLoader';
import ErrorComponent from '../../../../other/error/ErrorComponent';
import ModalConfirmDecline from '../../../../modals/modalConfirmDecline/ModalConfirmDecline';
import WinnersStyles from './WinnersStyles';
import { notification } from '../../../../../helpers/notifications/toastify';
import { ColumnDef } from '@tanstack/react-table';
import Pagination from '../../../../table/pagination/Pagination';
import ListMobile from './listMobile/ListMobile';
import { TPrize, TWinner } from '../../../../../types/treasureMap.types';
import { format } from 'date-fns';

function Winners() {
  const [params, setParams] = useSearchParams();

  const [tableData, setTableData] = useState<TWinner[]>([]);
  const [activePage, setActivePage] = useState<number>(() => {
    const pageFromParams = params.get('page');

    if (pageFromParams && !Number.isNaN(+pageFromParams) && +pageFromParams > 0) {
      return +pageFromParams;
    }

    return 1;
  });
  const [total, setTotal] = useState<number>(0);
  const [activeModalName, setActiveModalName] = useState<'deleting'>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataLoadingError, setDataLoadingError] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const windowSize = useWindowSize();

  async function getWinners() {
    try {
      setIsLoading(true);

      await workWithResponse(() => Api.getTreasuresMapWinners({ limit: 10, offset: (activePage - 1) * 10 })).then((res) => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          if (res.data && res.data.count && res.data.data) {
            setTotal(res.data.count);
            setTableData(res.data.data);
            setIsLoading(false);
          }
        } else {
          throw new Error();
        }
      });
    } catch (e: any) {
      console.error(e);
      setDataLoadingError(true);
      notification.error('Не удалось получить список победителей');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getWinners();
  }, [activePage]);

  const cols = useMemo<ColumnDef<TWinner>[]>(
    () => [
      {
        header: 'Пользователь',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => (
          <span className="textBase18">{row.cell.row.original.user.login}</span>
        ),
        accessorKey: 'user',
        enableSorting: false,
      },
      {
        header: 'Приз',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => (
          <span className="textBase18">{row.cell.row.original.prize}</span>
        ),
        accessorKey: 'prize',
        enableSorting: false,
      },
      {
        header: 'Дата и время',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => (
          <span className="textBase18">{row.cell.row.original.created_at ? format(new Date(row.cell.row.original.created_at), 'dd.MM.yyyy HH:mm') : 'Не известно'}</span>
        ),
        accessorKey: 'created_at',
        enableSorting: false,
      },
    ],
    [tableData],
  );

  return (
    <WinnersStyles>
      {dataLoadingError ? <ErrorComponent /> : (
        <>
          <div className="head">
            <h2 className="textSemiBold22">Победители</h2>
          </div>

          {isLoading ? <div className="loaderContainer"><MainLoader /></div> : (
            <div className="tableWrapper">
              {
                tableData && windowSize.width > 600 && (
                  <Table
                    data={tableData}
                    columns={cols}
                    withSorting={false}
                  />
                )
              }

              {
                windowSize.width <= 600 && (
                  <ListMobile
                    winners={tableData}
                  />
                )
               }
              {total > 0 && (
                <Pagination
                  activePage={activePage}
                  total={total}
                  setActivePage={(pageNumber) => setActivePage(pageNumber)}
                  extraClasses={windowSize.width <= 600 ? 'paginationMobile' : undefined}
                />
              )}
            </div>
          )}
        </>
      )}

    </WinnersStyles>
  );
}

export default Winners;
