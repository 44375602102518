import styled from 'styled-components';

export const DropDownStyles = styled.div`
  position: absolute;
  right: 50%;
  top: calc(100% + 8px);
  z-index: 1;
  border-radius: 2px;
  border: 1px solid var(--Red-2, #CB1F1F);
  background: var(--Cards, #171616);
  /* 300 */
  box-shadow: 0px 0px 16px 0px rgba(203, 31, 31, 0.16);
  
  button, a {
    color: #FFF;
  }
  
  color: #FFF;

  padding: 16px;
`;
