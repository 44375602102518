import styled from 'styled-components';

export const MenuStyles = styled.div`
  position: relative;
  
  .userMenu {
    display: flex;
    flex-direction: column;
    gap: 6px;
    
    button {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  
  .menu {
    position: absolute;
    z-index: 1;
    background: #FFF;
    padding: 5px;
    
    tab-index: -1;
    opacity: 0;
    transform: scale(0.8);
    width: 0;
    height: 0;
    
    transition: 0.3s ease all;
    
    &.menuOpen {
      tab-index: 0;
      opacity: 1;
      transform: scale(0.8);
    }
  }
`;
