import styled from 'styled-components';

const EmojiPickerStyles = styled.div`
  position: absolute;
  right: 0;
  top: 30px;
  width: 100%;
  z-index: 3;

  display: flex;
  flex-direction: column;
  gap: 8px;
  
  padding: 8px;
  box-sizing: border-box;
  border-radius: 12px;
  max-height: 280px;
  max-width: 280px;
  overflow-y: auto;
  
  background: var(--cards);
  
  .emojisPack {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  
  li {
    button {
      font-size: 24px;
    }
  }
`;

export default EmojiPickerStyles;
