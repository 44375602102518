import styled from 'styled-components';

export const PostsStyles = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  
  .loaderContainer {
    flex-grow: 1;
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .contentWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    max-width: 670px;

    .posts {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
      flex-grow: 1;
    }
  }
  
  .head {
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    margin-bottom: 24px;

    @media (min-width: 600px) {
      flex-direction: row;
      justify-content: space-between;
    }
    
    .searchInput {
      @media (min-width: 600px) {
        max-width: 298px;
        width: 100%;
      }
    }
  }
`;
