import {
  ChangeEvent, ReactNode, useRef, useState,
} from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { notification } from '../../../helpers/notifications/toastify';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';
import FormErrorMessage from '../formErrorMessage/FormErrorMessage';
import Button from '../../buttons/button/Button';
import Loader from '../../loader/Loader';
import FileInputStyles from './FileInputStyles';
import { boolean } from 'yup';

interface FileInputProps {
  text?: string;
  name: string;
  onFileUploaded: (result: string | File) => void;
  accept?: string;
  children?: ReactNode;
  uploadToServer?: boolean;
}

function FileInput({
  onFileUploaded, text, name, accept, children, uploadToServer = true,
}: FileInputProps) {
  const formik = useFormikContext<any>();

  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile) {
      try {
        setIsLoading(true);

        if (uploadToServer) {
          await workWithResponse(() => Api.createFile(uploadedFile)).then((res) => {
            if (res.data) {
              onFileUploaded(res.data.url);
              setIsLoading(false);
            } else {
              throw new Error(res.error || 'An error occurred while uploading file.');
            }
          });
        } else {
          setIsLoading(false);
          return onFileUploaded(uploadedFile);
        }
      } catch (e: any) {
        notification.error(e.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClick = () => {
    fileInputRef?.current?.click();
  };

  return (
    <FileInputStyles>
      {isLoading ? <Loader /> : (
        <Button
          text={text || 'Загрузить файл'}
          onClick={handleClick}
        />
      )}
      {children}
      {formik && formik.errors[name] && formik.touched[name] && <ErrorMessage name={name} component={FormErrorMessage} />}

      <input
        type="file"
        id={`file-input-${name}`}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept={accept}
        ref={fileInputRef}
      />
    </FileInputStyles>
  );
}

export default FileInput;
