import React from 'react';
import { ListMobileStyles } from './ListMobileStyles';
import { TApiPrize } from '../../../../../../types/treasureMap.types';
import Menu from '../menu/Menu';

interface ListMobileProps {
  prizes: TApiPrize[];
  onPrizeDelete(): void;
}

function ListMobile({
  prizes, onPrizeDelete,
}: ListMobileProps) {
  return (
    <ListMobileStyles>
      <ul>
        {prizes.map((prize, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>
            <div className="infoWrapper">
              <span className="textBase16 fontSemiBold">Меню</span>
              <Menu
                prize={prize}
                onPrizeDelete={onPrizeDelete}
              />
            </div>
            <div className="infoWrapper">
              <span className="textBase16 fontSemiBold">Описание</span>
              <span className="textBase16">{prize.name}</span>
            </div>
            <div className="infoWrapper">
              <span className="textBase16 fontSemiBold">Количество</span>
              <span className="textBase16">{prize.count}</span>
            </div>
          </li>
        ))}
      </ul>
    </ListMobileStyles>
  );
}

export default ListMobile;
