import styled from 'styled-components';

const FileInputStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  
  .button {
    width: fit-content;
  }
`;

export default FileInputStyles;
