import styled from 'styled-components';

const CreatePrizesStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  
  flex-grow: 1;
  max-width: 800px;
  
  form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export default CreatePrizesStyles;
