import styled from 'styled-components';

export const ModalStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(60, 60, 60, 0.60);
  display: grid;
  place-items: center;
  padding: 20px;
  box-sizing: border-box;
  
  .modalBody {
    padding: 30px;

    border-radius: 4px;
    border: 1px solid var(--Red-2, #CB1F1F);
    background: var(--Cards2, #1C1C1C);
    /* 400 */
    box-shadow: 0px 0px 32px 0px rgba(203, 31, 31, 0.20);   
    box-sizing: border-box;
  }
`;
