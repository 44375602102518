import React, { useMemo } from 'react';
import { PaginationStyles } from './PaginationStyles';
import Button from '../../buttons/button/Button';
import ArrowRightIcon from '../../../assets/icons/table/ArrowRightIcon';

export interface PaginationProps {
  total: number;
  activePage: number;
  elementsPerPage?: number;
  setActivePage(pageNumber: number): void;
  extraClasses?: string;
}

function Pagination({
  total, activePage, elementsPerPage, setActivePage, extraClasses,
}: PaginationProps) {
  const pagesCount = useMemo(() => Math.ceil(total / (elementsPerPage || 10)), [total]);

  return (
    <PaginationStyles className={`pagination ${extraClasses ?? ''}`}>
      <Button
        text="Первая"
        onClick={() => setActivePage(1)}
        theme="black"
        disabled={activePage === 1}
      />
      <Button
        Icon={<ArrowRightIcon />}
        onClick={() => setActivePage(activePage - 1)}
        theme="black"
        disabled={activePage === 1}
      />

      <div className="activePageText">
        <span>
          Страница
        </span>
        <span>{`${activePage} из ${pagesCount}`}</span>
      </div>

      <Button
        Icon={<ArrowRightIcon />}
        onClick={() => setActivePage(activePage + 1)}
        theme="black"
        disabled={activePage === pagesCount}
      />
      <Button
        text="Последняя"
        onClick={() => setActivePage(pagesCount)}
        theme="black"
        disabled={activePage === pagesCount}
      />
    </PaginationStyles>
  );
}

export default Pagination;
