import styled from 'styled-components';
import colors from '../../../../constants/colors';

export const SearchInputStyles = styled.div`
  label {
    display: flex;
    flex-direction: column;
    gap: 3px;
    color: var(--grey-1, #FEFEFE);
  }

  .inputWrap {
    display: flex;
    align-items: center;

    .inputContainer {
      position: relative;

      flex-grow: 1;
      display: grid;
      grid-template-columns: 1fr;

      .searchIcon {
        position: absolute;
        background: none;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    input {
      font-size: 16px;
      color: var(--grey-1, #FEFEFE);
      background-color: inherit;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 10px 8px 10px 40px;
      transition: 0.3s all;
      font-family: 'Inter', sans-serif;
      outline: none;

      &.greyBorder {
        border: 1px solid #C2C9D1;

        &:hover {
          border: 1px solid #b2bdc4;
        }

        &:focus {
          border: 1px solid #a0aeb6;
        }
      }

      &.redBorder {
        border: 1px solid #B54141;

        &:hover {
          border: 1px solid ${colors.main3};
        }

        &:focus {
          border: 1px solid ${colors.main5};
        }
      }


      &::placeholder {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        line-height: 22px;
        color: var(--grey-4);
      }

      &.filled {
        border: 1px solid ${colors.main3};
      }

      &.disabled {
        border: 1px solid rgba(140, 140, 140, 0.35);
      }
    }

    .childrenContainer {
      margin-bottom: 24px;
    }
  }

  .formErrorContainer {
    padding-top: 2px;

    display: flex;
    gap: 4px;

    color: #00000080;
  }

  .required {
    color: var(--grey-1, #FEFEFE);
  }
`;
