import { toast, ToastOptions } from 'react-toastify';

export const notification = {
  error: (text: string, options?: ToastOptions) => {
    toast.error(text, {
      ...options,
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  },

  success: (text: string, options?: ToastOptions) => {
    toast.success(text, {
      ...options,
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  },
};
