import styled from 'styled-components';
import colors from '../../../constants/colors';

export const FormErrorMessageStyles = styled.span`
  color: ${colors.main1};
  
  display: flex;
  gap: 8px;
  
  svg {
    transform: translateY(2px);
  }
`;
