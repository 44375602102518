import styled from 'styled-components';

const TreasuresMapStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  
  overflow: hidden;

  .loaderContainer {
    flex-grow: 1;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .head {
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    @media (min-width: 600px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

export default TreasuresMapStyles;
