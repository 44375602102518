import { useEffect, useState } from 'react';
import workWithResponse from '../../../../../helpers/workWithResponse';
import { Api } from '../../../../../api';
import { notification } from '../../../../../helpers/notifications/toastify';
import PostersStyles from './PostersStyles';
import Loader from '../../../../loader/Loader';
import { ApiPosterWithImageURL } from '../../../../../types/treasureMap.types';
import { mapFromApiPostersToApiPostersWithImageURL } from '../../../../../api/mappers/mappers';
import { TrashIcon } from '../../../../../assets/icons/createPost/TrashIcon';
import Poster from './poster/Poster';

function Posters() {
  const [posters, setPosters] = useState<null | ApiPosterWithImageURL[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  async function getPosters() {
    try {
      setIsLoading(true);

      const posters = await workWithResponse(() => Api.getPosters());

      if (posters.error) {
        throw new Error(posters.error);
      }

      if (posters.data) {
        setPosters(mapFromApiPostersToApiPostersWithImageURL(posters.data));
      }

      setIsLoading(false);
    } catch (e) {
      console.log(e);

      notification.error('Произошла ошибка при загрузке постеров');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getPosters();
  }, []);

  useEffect(() => () => {
    posters?.forEach((poster) => {
      if (poster.imageURL) {
        URL.revokeObjectURL(poster.imageURL);
      }
    });
  }, [posters]);

  return (
    <PostersStyles>
      {isLoading ? (
        <Loader />
      ) : posters && posters.length > 0 ? (
        <div className="posters">
          {
            posters.map((poster) => <Poster onPosterDeleted={getPosters} key={poster.ID} poster={poster} />)
          }
        </div>
      ) : (
        <span>Постеры не найдены</span>
      )}
    </PostersStyles>
  );
}

export default Posters;
