import styled from 'styled-components';

export const SidebarStyles = styled.div`
  position: fixed;
  top: 0;
  z-index: 1;
  box-sizing: border-box;
  height: 100vh;
  background: #0F0E0E;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  color: var(--White, #FDFDFD);

  background: rgba(23, 22, 22, 0.50);
  width: 100vw;
  transform: translateX(-100%);
  transition: 0.3s ease transform;

  @media (min-width: 1201px) {
    width: 280px;
    height: auto;
    background: none;
    transform: translateX(0);
  }

  nav {
    max-width: 280px;
    display: flex;
    flex-direction: column;

    gap: 58px;
    
    padding: 15px 32px 40px 32px;

    position: fixed;
    top: 0;
    z-index: 1;

    transform: translateX(-100%);

    background: #0F0E0E;

    width: 100%;
    box-sizing: border-box;
    height: 100vh;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    transition: 0.3s ease all;
    color: var(--Black, #141414);
    overflow-y: hidden;

    @media (min-width: 768px) {
      padding: 13px 32px 16px 32px;
    }

    @media (min-width: 1201px) {
      padding-top: 30px;
      max-width: 280px;
    }
  }

  &.open {
    transform: translateX(0);

    nav {
      transform: translateX(0);
    }
  }
  
  .menuButton {
    position: absolute;
    
    top: 11px;
    left: 22px;
    
    
    @media (min-width: 1201px) {
      display: none;
    }
  }
  
  .logoContainer {
    position: relative;
    margin: 0 auto 0 auto;
  }
  
  .options {
    a {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 12px 16px;

      color: var(--Grey-2, #D9D9D9);

      transition: 0.3s ease color, 0.3s ease background-color;

      svg {
        path {
          transition: 0.3s ease all;
        }
      }

      &:hover {
        background-color: var(--grey-5);
      }

      &.active {
        color: #FFF;
        background-color: var(--Red-2, #CB1F1F);
        border-radius: 4px;
      }
      
      &.analytics {
        svg {
          path {
            stroke: #FFF;
            fill: #141414;
          }
        }
      }
    }
  }
`;
