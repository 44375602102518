import React, { useEffect, useState } from 'react';
import { MainLayoutStyles } from './MainLayoutStyles';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from '../../sidebar/Sidebar';
import Header from '../header/Header';

function MainLayout() {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

  const location = useLocation();

  useEffect(() => {
    if (!location.hash) {
      window.scroll(0, 0);
    }
  }, [location.pathname]);

  return (
    <MainLayoutStyles>
      <div>
        <Header setSidebarOpen={() => setIsSidebarOpen(true)} />

        <aside>
          <Sidebar
            isOpen={isSidebarOpen}
            setSidebarOpen={() => setIsSidebarOpen(false)}
          />
        </aside>
      </div>
      <div>

        <main>
          <Outlet />
        </main>
      </div>
    </MainLayoutStyles>
  );
}

export default MainLayout;
