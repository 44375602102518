import React, { CSSProperties, useMemo, useState } from 'react';
import { SearchInputStyles } from './SearchInputStyles';
import classNames from 'classnames';

interface InputFieldProps {
  name: string;
  placeholder: string;
  // eslint-disable-next-line no-unused-vars
  onChange: any;
  extraBlockStyles?: CSSProperties;
  label?: string;
  onKeyUp?: () => void;
  disabled?: boolean;
  children?: any;
  autoComplete?: string | undefined;
  borderColors?: 'redBorder' | 'greyBorder',
}

export default function SearchInput({
  name,
  placeholder,
  label,
  onChange,
  onKeyUp,
  extraBlockStyles,
  disabled,
  children,
  autoComplete,
  borderColors = 'redBorder',
}: InputFieldProps) {
  const [isFilled, setIsFilled] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [stateValue, setStateValue] = useState<string>('');

  const handleChange = (inputValue: string) => {
    setStateValue(inputValue);
    setIsFilled(!!inputValue);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      onChange(inputValue);
    }, 400);

    setTimeoutId(newTimeoutId);
  };

  return (
    <SearchInputStyles style={extraBlockStyles} className="searchInput">
      <label htmlFor={name}>
        {label && (
          <span className="textSemiBold12 colorSecondary8 label">{label}</span>
        )}
        <div className="inputWrap">
          <div className="inputContainer">
            <svg className="searchIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z" fill="#D9D9D9" />
              <path d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z" fill="#D9D9D9" />
            </svg>

            <input
              id={name}
              value={stateValue}
              type="text"
              onChange={(e) => handleChange(e.target.value)}
              autoComplete={autoComplete}
              onKeyUp={onKeyUp}
              placeholder={placeholder}
              disabled={disabled}
              className={classNames(`${borderColors}`, { filled: isFilled, disabled })}
            />
          </div>
          <div className="childrenContainer">{children}</div>
        </div>
      </label>
    </SearchInputStyles>
  );
}
