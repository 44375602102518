import { TrashIcon } from '../../../../../../assets/icons/createPost/TrashIcon';
import { ApiPosterWithImageURL } from '../../../../../../types/treasureMap.types';
import { useState } from 'react';
import DeleteModal from './deleteModal/DeleteModal';
import PosterStyles from './PosterStyles';

function Poster({ poster, onPosterDeleted }: {poster: ApiPosterWithImageURL, onPosterDeleted: (poster: ApiPosterWithImageURL) => void;}) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <PosterStyles className="poster" key={poster.ID}>
      {deleteModalOpen && (
        <DeleteModal
          poster={poster}
          onPosterDeleted={onPosterDeleted}
          handleClose={() => setDeleteModalOpen(false)}
        />
      )}

      <button className="deleteButton" type="button" onClick={() => setDeleteModalOpen(true)}>{TrashIcon}</button>

      <div className="imageWrapper">
        <img src={poster.imageURL} alt="" />
      </div>

      <div className="coordinates textBase16">
        <span>{`X ${poster.start_x / 10}/${poster.end_x / 10}`}</span>
        <span>{`Y ${poster.start_y / 10}/${poster.end_y / 10}`}</span>
      </div>
    </PosterStyles>
  );
}

export default Poster;
