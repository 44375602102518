import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuStyles } from './MenuStyles';
import DeleteIcon from '../../../assets/icons/table/DeleteIcon';
import BlockIcon from '../../../assets/icons/table/BlockIcon';
import MenuIcon from '../../../assets/icons/header/MenuIcon';
import DropDown from '../../other/dropDown/DropDown';
import { ApiUser } from '../../../types/user.types';

interface MenuProps {
  user: ApiUser;
  handleDelete(user: ApiUser): void;
  handleBlock(user: ApiUser): void;
  handleRatingModal(user: ApiUser): void;
}

function Menu({
  user, handleDelete, handleBlock, handleRatingModal,
}: MenuProps) {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const menuRef = useRef<HTMLDivElement | null>(null);

  return (
    user.id === 1 ? null : (
      <MenuStyles ref={menuRef}>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            setMenuOpen(!menuOpen);
          }}
        >
          <MenuIcon />
        </button>

        <DropDown onClose={() => setMenuOpen(false)} isOpened={menuOpen} exclude={menuRef}>
          <button type="button" onClick={() => handleBlock(user)}>
            {BlockIcon}
            {' '}
            {user.blocked ? 'Разблокировать' : 'Заблокировать'}
          </button>

          <button type="button" onClick={() => handleRatingModal(user)}>
            Изменить рейтинг
          </button>

          <button type="button" onClick={() => handleDelete(user)}>
            {DeleteIcon}
            {' '}
            Удалить
          </button>
        </DropDown>
      </MenuStyles>
    )
  );
}

export default Menu;
