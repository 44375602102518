import styled from 'styled-components';

export const ErrorComponentStyles = styled.div`
  flex-grow: 1;
  padding: 40px 74px 110px;
  
  .errorContainer {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    color: var(--error-default);
    
    text-align: center;
  }
`;
