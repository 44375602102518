import React, {
  JSX, useEffect, useRef, useState,
} from 'react';
import { SidebarStyles } from './SidebarStyles';
import { Link, useLocation } from 'react-router-dom';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useAppSelector } from '../../state/hooks';
import classNames from 'classnames';
import Logo from '../other/logo/Logo';
import { useWindowSize } from 'usehooks-ts';
import CloseMenuIcon from '../../assets/icons/header/CloseMenuIcon';
import { PostsIcon } from '../../assets/icons/sidebar/PostsIcon';
import { UserIcon } from '../../assets/icons/sidebar/UserIcon';
import { TreasuresMapIcon } from '../../assets/icons/sidebar/TreasuresMapIcon';

interface SidebarProps {
  setSidebarOpen(): void;
  isOpen: boolean;
}

function Sidebar({ isOpen, setSidebarOpen }: SidebarProps) {
  const [sidebarOptions, setSidebarOptions] = useState<{name: string, path: string, icon: JSX.Element}[]>([]);

  const { data: user } = useAppSelector((state) => state.user);

  const { width } = useWindowSize();

  const location = useLocation();

  const sidebarRef = useRef<HTMLElement | null>(null);

  useClickOutside(sidebarRef, () => setSidebarOpen());

  useEffect(() => {
    if (user) {
      const options = [
        {
          name: 'Пользователи',
          path: '/users',
          icon: UserIcon,
        },
        {
          name: 'Посты',
          path: '/posts',
          icon: PostsIcon,
        },
        {
          name: 'Карта сокровищ',
          path: '/games/treasures-map',
          icon: TreasuresMapIcon,
        },
      ];

      setSidebarOptions(options);
    }
  }, [user]);

  return (
    <SidebarStyles className={width > 1200 ? 'open boxShadow1' : isOpen ? 'boxShadow1 open' : 'boxShadow1'}>
      <nav ref={sidebarRef}>
        <div className="logoContainer">
          <Link to="/"><Logo /></Link>
        </div>

        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button onClick={() => setSidebarOpen()} className="menuButton" type="button">
          <CloseMenuIcon />
        </button>

        <div className="options">
          {sidebarOptions.map((option) => (
            <Link
              onClick={() => setSidebarOpen()}
              to={`${option.path}${location.pathname.startsWith(option.path) && location.search ? location.search : ''}`}
              className={classNames({ analytics: location.pathname.startsWith('/analytics') && option.path === '/analytics', active: location.pathname.startsWith(`/${option.path.split('/')[1]}`) })}
              key={option.name}
              title={`Перейти на страницу ${option.name}`}
            >
              {option.icon}

              <span className="textSemiBold16">{option.name}</span>
            </Link>
          ))}
        </div>
      </nav>
    </SidebarStyles>
  );
}

export default Sidebar;
