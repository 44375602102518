import styled from 'styled-components';

export const UsersStyles = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  
  .loaderContainer {
    flex-grow: 1;
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .tableWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    
    table {
      margin-bottom: 100px;
      padding-bottom: -100px;
    }
  }
  
  .modalBody {
    @media (min-width: 768px) {
      max-width: 630px;
      width: 100%;
    }
  }
  
  .head {
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    margin-bottom: 24px;

    @media (min-width: 600px) {
      flex-direction: row;
      justify-content: space-between;
    }
    
    .searchInput {
      @media (min-width: 600px) {
        max-width: 298px;
        width: 100%;
      }
    }
  }
`;
