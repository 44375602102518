import IApi from './IApi';
import ApiResponse from './entities/ApiResponse';
import ChangeUserData from '../state/entities/ChangeUserData';
import ApiAuthResponse from './entities/ApiAuthResponse';
import RegisterData from '../state/entities/RegisterData';
import { mapFromFileToFormData } from './mappers/mappers';
import { LoginData } from '../types/authentefication.types';
import { ApiUser, ApiUserList, ICreateUserData } from '../types/user.types';
import { fakeFile } from '../constants/fakeData';
import { TCreatedPost, TCreatePost, TPostArray } from '../types/post.types';
import { ApiFile } from '../types/file.types';
import {
  ApiPoster,
  TApiPrize,
  TApiTreasuresMapRulesAndPrizes,
  TPrize,
  TTreasureMapImage,
  TTreasuresGameData,
  TWinner,
} from '../types/treasureMap.types';
import { cleanBase64, fileToBase64 } from './helpers/helpers';
import { addSeconds, isAfter } from 'date-fns';
import { logout } from '../utils/logout';

export default class Api implements IApi {
  private baseUrl: string = process.env.REACT_APP_API_HOST!;

  private isRefreshing = false;

  private refreshPromise: Promise<any> | null = null;

  private async fetchData(path: string, requestOptions: any): Promise<any> {
    try {
      const accessTokenExpiredAt = localStorage.getItem(
        'access_token_expired_at',
      );
      if (
        accessTokenExpiredAt
        && isAfter(new Date(), new Date(accessTokenExpiredAt))
        && !path.includes('refresh')
      ) {
        await this.refreshToken();
        const newAccessToken = localStorage.getItem('access_token');
        if (newAccessToken) {
          requestOptions.headers.Authorization = `Bearer ${newAccessToken}`;
        }
      }

      const response = await fetch(`${this.baseUrl}${path}`, {
        ...requestOptions,
      });
      const statusCode = response.status;

      if (statusCode === 401) {
        await logout();
      }

      let data;
      const contentType = response.headers.get('Content-Type');
      if (contentType?.includes('application/json')) {
        data = await response.json();
      } else if (
        contentType?.includes('application/octet-stream')
        || contentType?.includes('image/png')
      ) {
        data = await response.blob();
      } else {
        data = await response.text();
      }

      return { data, statusCode };
    } catch (e) {
      console.error('FETCH ERROR:', e);
      throw new Error(`API Fetch error: ${e}`);
    }
  }

  private async getData(path: string, tokenRequired = true): Promise<any> {
    const myHeaders: { [key: string]: string } = {};

    if (tokenRequired) {
      const accessToken = localStorage.getItem('access_token');
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions: {
      method: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;
      headers: { [key: string]: string };
    } = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };
    return this.fetchData(path, requestOptions);
  }

  private async postData(
    path: string,
    data?: any,
    formData?: any,
    tokenRequired = true,
  ): Promise<any> {
    const myHeaders: { [key: string]: string } = {};
    if (!formData) {
      myHeaders['Content-Type'] = 'application/json';
    }

    if (tokenRequired) {
      const accessToken = localStorage.getItem('access_token');
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions: {
      method: string;
      headers: { [key: string]: string };
      body: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;

      mode: string;
      cache: string;
      credentials: string;
      referrerPolicy: string;
    } = {
      method: 'POST',
      headers: myHeaders,
      body: formData ? data : JSON.stringify(data),
      redirect: 'follow',

      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      referrerPolicy: 'no-referrer', // no-referrer, *client
    };

    return this.fetchData(path, requestOptions);
  }

  async putData(
    path: string,
    data: any,
    formData?: boolean,
    tokenRequired = true,
  ) {
    const myHeaders: { [key: string]: string } = {};
    if (!formData) {
      myHeaders['Content-Type'] = 'application/json';
    }

    if (tokenRequired) {
      const accessToken = localStorage.getItem('access_token');
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: formData ? data : JSON.stringify(data),
      redirect: 'follow',
    };

    return this.fetchData(path, requestOptions);
  }

  private async deleteData(
    path: string,
    data?: any,
    tokenRequired = true,
  ): Promise<any> {
    const myHeaders: { [key: string]: string } = {};

    if (tokenRequired) {
      const accessToken = localStorage.getItem('access_token');
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions: {
      method: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;
      body: string;
      headers: { [p: string]: string };
    } = {
      method: 'DELETE',
      redirect: 'follow',
      headers: myHeaders,
      body: JSON.stringify(data),
    };

    return this.fetchData(path, requestOptions);
  }

  private async patchData(
    path: string,
    data: any,
    tokenRequired = true,
  ): Promise<any> {
    const myHeaders: { [key: string]: string } = {};

    if (tokenRequired) {
      const accessToken = localStorage.getItem('access_token');
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions: {
      method: string;
      headers: { [key: string]: string };
      body: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;
    } = {
      method: 'PATCH',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow',
    };

    return this.fetchData(path, requestOptions);
  }

  public async getMyAccount(): Promise<ApiResponse<ApiUser>> {
    return this.getData('/users/me');
  }

  public async logout(): Promise<ApiResponse<any>> {
    return this.getData('/logout');
  }

  public async changeUserData(
    data: ChangeUserData,
  ): Promise<ApiResponse<ApiUser>> {
    return this.putData(`/users/${data.id}`, data);
  }

  public async attachRoles(
    roleIds: number[],
    userId: number,
  ): Promise<ApiResponse<{ message: string }>> {
    return this.postData(`/users/${userId}/attach`, { role_ids: roleIds });
  }

  public async detachRoles(
    roleIds: number[],
    userId: number,
  ): Promise<ApiResponse<{ message: string }>> {
    return this.postData(`/users/${userId}/detach`, { role_ids: roleIds });
  }

  public async createUser(
    data: ICreateUserData,
  ): Promise<ApiResponse<ApiUser>> {
    return this.postData('/users/', data);
  }

  public async searchUsersByLogin({
    login,
  }: {
    login: string;
  }): Promise<ApiResponse<ApiUser[]>> {
    return this.getData(`/connections/${login}`);
  }

  public async getUser(id: number): Promise<ApiResponse<ApiUser>> {
    return this.getData(`/users/${id}`);
  }

  public async getUsers(
    offset: number,
    limit?: number,
  ): Promise<ApiResponse<{ data: ApiUserList; count: number }>> {
    return this.getData(
      `/users/?limit=${limit}&offset=${offset}&order=desc&sortBy=id`,
    );
  }

  public async deleteUser(
    id: number,
  ): Promise<ApiResponse<{ message: string }>> {
    return this.deleteData(`/users/${id}/admin`);
  }

  public async blockUser(
    id: number,
  ): Promise<ApiResponse<{ message: string }>> {
    return this.patchData(`/users/${id}`, { blocked: true });
  }

  public async unblockUser(
    id: number,
  ): Promise<ApiResponse<{ message: string }>> {
    return this.patchData(`/users/${id}`, { blocked: false });
  }

  public async login(data: LoginData): Promise<ApiResponse<ApiAuthResponse>> {
    return this.postData('/login', data);
  }

  public async refreshToken(): Promise<ApiResponse<ApiAuthResponse>> {
    if (this.isRefreshing) {
      return this.refreshPromise!;
    }

    this.isRefreshing = true;
    this.refreshPromise = this.getData('/refresh', true)
      .then((token) => {
        if (token.statusCode >= 200 && token.statusCode < 300) {
          localStorage.setItem('access_token', token.data.access_token!);
          localStorage.setItem(
            'access_token_expired_at',
            addSeconds(
              new Date(),
              token.data.access_token_expired_at,
            ).toISOString(),
          );
          localStorage.setItem(
            'refresh_token_expired_at',
            addSeconds(
              new Date(),
              token.data.refresh_token_expired_at,
            ).toISOString(),
          );
          localStorage.setItem('refresh_token', token.data.refresh_token!);
          return token;
        }

        if (token.statusCode === 401) {
          logout();
        }

        throw new Error('Failed to refresh token');
      })
      .finally(() => {
        this.isRefreshing = false;
        this.refreshPromise = null;
      });

    return this.refreshPromise;
  }

  public async register(
    data: RegisterData,
  ): Promise<ApiResponse<ApiAuthResponse>> {
    return this.postData('/registration', data);
  }

  public async createPost(
    data: TCreatePost,
  ): Promise<ApiResponse<TCreatedPost>> {
    return this.postData('/posts/', data);
  }

  public async getPosts(
    offset: number,
    limit?: number,
  ): Promise<ApiResponse<{ data: TPostArray; total: number }>> {
    return this.getData(`/posts/?offset=${offset || 0}&limit=${limit || 10}`);
  }

  public async deletePost(
    id: number,
  ): Promise<ApiResponse<{ message: string }>> {
    return this.deleteData(`/posts/${id}`);
  }

  public async createFile(file: File): Promise<ApiResponse<ApiFile>> {
    const formData = mapFromFileToFormData(file);

    return this.postData('/files/', formData, true);
  }

  // eslint-disable-next-line class-methods-use-this
  public async getFile(id: number): Promise<ApiResponse<ApiFile>> {
    // return this.getData(`/files/${id}`);

    return Promise.resolve({ data: fakeFile, statusCode: 200 });
  }

  public async updateFile(
    id: number,
    data: { file: File; width?: number; height?: number },
  ): Promise<ApiResponse<ApiFile>> {
    const formDataFromData = mapFromFileToFormData(data.file);
    return this.putData(`/file/${id}`, formDataFromData, true);
  }

  public async deleteFile(
    id: number,
  ): Promise<ApiResponse<{ message: string }>> {
    return this.deleteData(`/file/${id}`);
  }

  public async getTreasuresMapImage(): Promise<ApiResponse<{ url: string }>> {
    const response = await this.getData('/games/');

    const url = URL.createObjectURL(response.data);

    return { statusCode: response.statusCode, data: { url } };
  }

  public async updateGameImage(
    data: TTreasureMapImage,
  ): Promise<ApiResponse<any>> {
    return this.putData('/games/', data);
  }

  public async createGameImage(
    data: TTreasureMapImage,
  ): Promise<ApiResponse<any>> {
    const base64 = await fileToBase64(data.data);

    const dataToPost = { ...data, data: cleanBase64(base64) };

    return this.postData('/boxes/', dataToPost);
  }

  public async getPosters(): Promise<ApiResponse<ApiPoster[]>> {
    return this.getData('/boxes/');
  }

  public async deletePoster(
    id: number,
  ): Promise<ApiResponse<{ message: string }>> {
    return this.deleteData(`/boxes/${id}`);
  }

  public async getTreasuresMapPrizes(): Promise<ApiResponse<TApiPrize[]>> {
    return this.getData('/prizes/');
  }

  public async createPrize(data: TPrize): Promise<ApiResponse<string>> {
    return this.postData('/prizes/', data);
  }

  public async deletePrize(id: number): Promise<ApiResponse<string>> {
    return this.deleteData(`/prizes/${id}`);
  }

  public async changeTreasuresMapPrizesAndRules(
    data: Partial<TApiTreasuresMapRulesAndPrizes>,
  ): Promise<ApiResponse<TApiTreasuresMapRulesAndPrizes>> {
    return this.putData('/games/', data);
  }

  public async getTreasuresMapGameData(): Promise<
    ApiResponse<TTreasuresGameData>
    > {
    return this.getData('/games/game');
  }

  public async getTreasuresMapWinners({
    offset,
    limit,
  }: {
    limit: number;
    offset: number;
  }): Promise<ApiResponse<{ data: TWinner[]; count: number }>> {
    return this.getData(`/games/winners?limit=${limit}&offset=${offset}`);
  }
}
