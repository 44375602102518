import { FormErrorMessageStyles } from './FormErrorMessageStyles';
import React from 'react';
import ErrorIcon from '../../../assets/icons/formElements/ErrorIcon';

function FormErrorMessage({ children }: { children?: any }) {
  return (
    <FormErrorMessageStyles className="textBase14 textMedium">
      <ErrorIcon />

      {children}
    </FormErrorMessageStyles>
  );
}

export default FormErrorMessage;
