import React, {
  Fragment, useCallback, useEffect, useMemo, useState,
} from 'react';
import { TPostArray } from '../../types/post.types';
import { useNavigate } from 'react-router-dom';
import workWithResponse from '../../helpers/workWithResponse';
import { Api } from '../../api';
import ErrorComponent from '../../components/other/error/ErrorComponent';
import MainLoader from '../../components/other/loaders/MainLoader';
import Pagination from '../../components/table/pagination/Pagination';
import { useWindowSize } from 'usehooks-ts';
import { PostsStyles } from './PostsStyles';
import Post from '../../components/posts/post/Post';
import CreatePost from '../../components/posts/create/CreatePost';

async function getPosts(
  activePage: number,
  total: number,
  setDataLoadingError: React.Dispatch<React.SetStateAction<boolean>>,
  setTotal: React.Dispatch<React.SetStateAction<number>>,
  setPosts: React.Dispatch<React.SetStateAction<TPostArray>>,
) {
  await workWithResponse(() => Api.getPosts((activePage - 1) * 10, 10)).then((res) => {
    if (res.error) {
      setDataLoadingError(true);
    } else if (res.data) {
      const { data, total: apiTotal } = res.data;

      setPosts((data || []));

      if (total !== apiTotal) {
        setTotal(apiTotal);
      }
    }
  }).catch((e) => {
    setDataLoadingError(true);
  });
}

function Posts() {
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  const [posts, setPosts] = useState<TPostArray>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataLoadingError, setDataLoadingError] = useState<boolean>(false);

  useEffect(() => {
    const searchParams = new URLSearchParams();
    searchParams.set('page', String(activePage));

    navigate({ search: `?${searchParams.toString()}` });

    getPosts(activePage, total, setDataLoadingError, setTotal, setPosts);
  }, [activePage, navigate]);

  const onPostDelete = useCallback(async (post_id: number) => {
    const newPost = (activePage * 10) + 1 >= total ? await workWithResponse(() => Api.getPosts((activePage * 10), 1)).then((res) => {
      if (!res.error && res.data) {
        return res.data.data;
      }
    }) : undefined;

    setTotal((total) => total - 1);
    setPosts((posts) => [...posts.filter((post) => post.id !== post_id), ...(newPost || [])]);
  }, [posts]);

  return (
    <PostsStyles>
      {dataLoadingError ? <ErrorComponent /> : (
        <>
          <div className="head">
            <h1 className="textBold28">Посты</h1>
          </div>

          {isLoading ? <div className="loaderContainer"><MainLoader /></div> : (
            <div className="contentWrapper">
              <CreatePost
                onPostCreate={(createdPost) => {
                  setPosts([createdPost, ...posts.slice(0, 9)]);
                }}
              />

              <ul className="posts">
                {posts.map((post) => <li key={post.id}><Post post={post} onPostDelete={onPostDelete} /></li>)}
              </ul>

              {total > 0 && (
              <Pagination
                activePage={activePage}
                total={total}
                setActivePage={(pageNumber) => setActivePage(pageNumber)}
                extraClasses={windowSize.width <= 600 ? 'paginationMobile' : undefined}
              />
              )}
            </div>
          )}
        </>
      )}
    </PostsStyles>
  );
}

export default Posts;
