import React, { useState } from 'react';
import ModalConfirmDecline from '../../../../../../modals/modalConfirmDecline/ModalConfirmDecline';
import { notification } from '../../../../../../../helpers/notifications/toastify';
import workWithResponse from '../../../../../../../helpers/workWithResponse';
import { Api } from '../../../../../../../api';
import { ApiPosterWithImageURL } from '../../../../../../../types/treasureMap.types';

interface DeleteModalProps {
  handleClose: () => void;
  onPosterDeleted: (poster: ApiPosterWithImageURL) => void;
  poster: ApiPosterWithImageURL;
}

function DeleteModal({ handleClose, poster, onPosterDeleted }: DeleteModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const deleteUser = async () => {
    try {
      setIsLoading(true);

      await workWithResponse(() => Api.deletePoster(poster.ID)).then((res) => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          setIsLoading(false);
          handleClose();
          onPosterDeleted(poster);
        }
      });
    } catch (e) {
      console.error(e);
      notification.error('Произошла ошибка при удалении');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalConfirmDecline
      onClose={() => (isLoading ? () => null : handleClose())}
      title="Удалить банер?"
      buttons={
        [
          {
            text: 'Отмена',
            onClick: () => {
              handleClose();
            },
            theme: 'black',
            isLoading,
          },
          {
            text: 'Удалить',
            onClick: () => {
              deleteUser();
            },
            theme: 'red',
            isLoading,
          },
        ]
      }
    />
  );
}

export default DeleteModal;
