import colors from '../../constants/colors';
import styled from 'styled-components';

export const LoginStyles = styled.div`
  min-height: 100vh;
  height: 100%;
  
  .content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    gap: 154px;
    
    padding: 40px 0;
    box-sizing: border-box;
    
    min-height: 100vh;
    height: 100%;

    .buttonWrapper {
      position: relative;
      
      .errorText {
        position: absolute;
      }
    }

    .form {
      max-width: 386px;
      width: 100%;
      
      padding: 48px;
      margin-top: -134px;

      box-sizing: border-box;

      border-radius: 4px;
      border: 1px solid var(--red-3, #A21919);
      background: #171616;

      /* 400 */
      box-shadow: 0px 0px 32px 0px rgba(203, 31, 31, 0.20);
      
      .title {
        color: #FFF;
        text-align: center;
        margin-bottom: 24px;
      }
      
      .fields {
        display: grid;
        gap: 16px;
        
        margin-bottom: 32px;
      }

      .footer {
        text-align: center;
        color: #FFF;
        
        a {
          color: var(--red-2, #CB1F1F);
          
          transition: 0.3s ease color;
          
          &:hover {
            color: var(--red-3, #CB1F1F);
          }
        }
      }
    }
  }
`;
