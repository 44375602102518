import { FormikProvider, useFormik } from 'formik';
import { TTreasureMapImage } from '../../../types/treasureMap.types';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';
import { notification } from '../../../helpers/notifications/toastify';
import CreatePosterStyles from './CreatePosterStyles';
import React, { useEffect, useMemo, useState } from 'react';
import fakeMap from '../../../assets/testImages/fakeMap.png';
import Loader from '../../../components/loader/Loader';
import classNames from 'classnames';
import * as Yup from 'yup';
import AddFilesModal from '../../../components/posts/create/addFilesModal/AddFilesModal';
import FileInput from '../../../components/form/fileInput/FileInput';
import Button from '../../../components/buttons/button/Button';
import { useNavigate } from 'react-router-dom';
import SearchInput from '../../../components/form/searchInput/input/SearchInput';

type FormValues = {
  data: null | File,
  start_x: null | number,
  end_x: null | number,
  end_y: null | number,
  start_y: null | number,
}

const hundredItemsArray: null[] = [];
hundredItemsArray.length = 100;
hundredItemsArray.fill(null);

const validationSchema = Yup.object().shape({
  data: Yup.mixed()
    .required('Загрузите изображение')
    .test(
      'is-valid-size',
      'Размеры изображения не соответствуют размерам выбраной области',
      function (value) {
        const {
          start_x, end_x, start_y, end_y,
        } = this.parent as FormValues;

        if (
          start_x === null
          || end_x === null
          || start_y === null
          || end_y === null
        ) {
          return this.createError({
            message: 'Укажите координаты, чтобы проверить изображение.',
          });
        }

        const validStartX = Math.min(start_x, end_x);
        const validEndX = Math.max(start_x, end_x);
        const validStartY = Math.min(start_y, end_y);
        const validEndY = Math.max(start_y, end_y);

        const targetWidth = (validEndX - validStartX + 1) * 10;
        const targetHeight = (validEndY - validStartY + 1) * 10;

        if (!value || !(value instanceof File)) {
          return false;
        }

        return new Promise<boolean>((resolve) => {
          const img = new Image();
          const objectURL = URL.createObjectURL(value);

          img.onload = () => {
            const isValid = img.width === targetWidth && img.height === targetHeight;
            URL.revokeObjectURL(objectURL);
            resolve(isValid);
          };

          img.onerror = () => {
            URL.revokeObjectURL(objectURL);
            resolve(false);
          };

          img.src = objectURL;
        });
      },
    ),
  start_x: Yup.number().nullable().required('Выберите координату'),
  start_y: Yup.number().nullable().required('Выберите координату'),
  end_x: Yup.number().nullable().required('Выберите координату'),
  end_y: Yup.number().nullable().required('Выберите координату'),
});

function CreatePoster() {
  const [isLoading, setIsLoading] = useState(true);
  const [map, setMap] = useState<null | string>(null);
  const navigate = useNavigate();

  const formik = useFormik<FormValues>({
    initialValues: {
      data: null,
      start_x: null,
      end_x: null,
      end_y: null,
      start_y: null,
    },
    onSubmit: async (values) => {
      if (
        (values.start_x || values.start_x === 0)
        && (values.end_x || values.end_x === 0)
        && (values.start_y || values.start_y === 0)
        && (values.end_y || values.end_y === 0)
        && values.data
      ) {
        const dataToSubmit: TTreasureMapImage = {
          data: values.data,
          start_x: values.start_x > values.end_x ? (values.end_x) * 10 : (values.start_x) * 10,
          end_x: values.end_x < values.start_x ? (values.start_x + 1) * 10 : (values.end_x + 1) * 10,
          end_y: values.start_y > values.end_y ? (values.start_y + 1) * 10 : (values.end_y + 1) * 10,
          start_y: values.start_y > values.end_y ? (values.end_y) * 10 : (values.start_y) * 10,
        };

        // if (dataToSubmit.start_x === 0) {
        //   dataToSubmit.start_x = 1;
        // }
        //
        // if (dataToSubmit.start_y === 0) {
        //   dataToSubmit.start_y = 1;
        // }

        const response = await workWithResponse(() => Api.createGameImage(dataToSubmit)).then((response) => {
          if (response.statusCode >= 200 && response.statusCode < 300) {
            notification.success('Банер успешно добавлен');
            navigate('/games/treasures-map');
            formik.setSubmitting(false);
          }
        }).finally(() => {
          formik.setSubmitting(false);
        });
      } else {
        notification.error('Контент поста не может быть пустым. Для сохранения поста, добавьте файл или текст.');
      }
    },
    validationSchema,
  });

  useEffect(() => {
    async function getInitialData() {
      try {
        const mapResponse = await workWithResponse(() => Api.getTreasuresMapImage());

        if (mapResponse.error) {
          throw new Error(mapResponse.error);
        }

        setMap(mapResponse.data!.url);
        setIsLoading(false);
      } catch (e) {
        notification.error('Произошла ошибка при загрузке карты');
      } finally {
        setIsLoading(false);
      }
    }

    getInitialData();
  }, []);

  useEffect(() => () => {
    if (map) {
      URL.revokeObjectURL(map);
    }
  }, [map]);

  const handleClickToCell = (x: number, y: number) => {
    if (formik.values.start_x === null || formik.values.start_y === null) {
      formik.setFieldValue('start_x', x);
      formik.setFieldValue('start_y', y);
    } else if (formik.values.end_y || formik.values.end_x) {
      formik.setFieldValue('start_x', null);
      formik.setFieldValue('start_y', null);
      formik.setFieldValue('end_x', null);
      formik.setFieldValue('end_y', null);
    } else {
      formik.setFieldValue('end_x', x);
      formik.setFieldValue('end_y', y);
    }
  };

  const isCellSelected = (x: number, y: number): boolean => {
    const {
      start_x, start_y, end_x, end_y,
    } = formik.values;

    if (start_x !== null && start_y !== null) {
      if (end_x === null || end_y === null) {
        return x === start_x && y === start_y;
      }

      const xMin = Math.min(start_x, end_x);
      const xMax = Math.max(start_x, end_x);
      const yMin = Math.min(start_y, end_y);
      const yMax = Math.max(start_y, end_y);

      return x >= xMin && x <= xMax && y >= yMin && y <= yMax;
    }

    return false;
  };

  const selectedAreaInPixels = useMemo(() => {
    if (
      (formik.values.start_x || formik.values.start_x === 0)
      && (formik.values.end_x || formik.values.end_x === 0)
      && (formik.values.start_y || formik.values.start_y === 0)
      && (formik.values.end_y || formik.values.end_y === 0)
    ) {
      const coordinates = {
        data: formik.values.data,
        start_x: formik.values.start_x > formik.values.end_x ? formik.values.end_x : formik.values.start_x,
        end_x: formik.values.end_x < formik.values.start_x ? formik.values.start_x : formik.values.end_x,
        end_y: formik.values.start_y > formik.values.end_y ? formik.values.start_y : formik.values.end_y,
        start_y: formik.values.start_y > formik.values.end_y ? formik.values.end_y : formik.values.start_y,
      };

      return `Выбрана зона ${(coordinates.end_x - coordinates.start_x + 1) * 10}x${(coordinates.end_y - coordinates.start_y + 1) * 10} пикселей`;
    }

    return '';
  }, [formik.values]);

  return (
    <CreatePosterStyles>
      <div className="head">
        <h1 className="textBold28">Добавление изображения на карту</h1>
      </div>

      {isLoading || formik.isSubmitting ? (
        <div className="loaderWrapper">
          <Loader />
        </div>
      ) : (
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div
              className="map"
            >
              <div className="mapImage">
                <img src={map!} alt="" />
              </div>

              {hundredItemsArray.map((_, y) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="row" data-row={y + 1} key={y}>
                  {hundredItemsArray.map((_, x) => (
                    // eslint-disable-next-line jsx-a11y/control-has-associated-label
                    <button
                      data-col={x + 1}
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={x}
                      title={`Строка - ${y + 1} / Колонка - ${x + 1}`}
                      style={{ width: 10, height: 10 }}
                      className={classNames('cellButton', { firstRow: y === 0, selected: isCellSelected(x, y) })}
                      type="button"
                      onClick={() => handleClickToCell(x, y)}
                    />
                  ))}
                </div>
              ))}
            </div>

            {selectedAreaInPixels && <span className="textSemiBold16">{selectedAreaInPixels}</span>}

            <FileInput
              onFileUploaded={(file) => formik.setFieldValue('data', file)}
              name="data"
              accept="image/png"
              text="Загрузить изображение"
              uploadToServer={false}
            >
              {formik.values.data
                && <img src={URL.createObjectURL(formik.values.data)} style={{ width: 'fit-content', maxWidth: '100%' }} alt="" />}
            </FileInput>

            <div className="formButtonsWrapper">
              <Button
                text="Назад"
                onClick={() => navigate('/games/treasures-map')}
                type="button"
                theme="black"
              />

              <Button
                text="Сохранить"
                onClick={formik.handleSubmit}
                type="submit"
              />
            </div>
          </form>
        </FormikProvider>
      )}
    </CreatePosterStyles>
  );
}

export default CreatePoster;
