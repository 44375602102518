import React, { useState } from 'react';
import { TPost } from '../../../types/post.types';
import UserLogo from '../../other/userLogo/UserLogo';
import { isToday, isYesterday, format } from 'date-fns';
import { PostStyles } from './PostStyles';
import Menu from './menu/Menu';
import { useAppSelector } from '../../../state/hooks';

interface PostProps {
  post: TPost;
  onPostDelete(id: number): void;
}

function generatePostCreateDate(date: Date) {
  if (isToday(date)) {
    return `Сегодня в ${format(date, 'HH:mm')}`;
  }

  if (isYesterday(date)) {
    return `Вчера в ${format(date, 'HH:mm')}`;
  }

  return `${format(date, 'dd:MM:Y')} в ${format(date, 'HH:mm')}`;
}

function Post({ post, onPostDelete }: PostProps) {
  const [unfoldText, setUnfoldText] = useState<boolean>(post.text.length < 500);

  const { data: user } = useAppSelector((state) => state.user);

  return (
    <PostStyles className="post">
      <div className="postHead">
        <div className="leftPart">
          <div className="userWrapper">
            <UserLogo logo={post.user.file_url ? post.user.file_url : null} name={post.user.login} />
          </div>

          <div className="userNameAndDateWrapper">
            <span className="fontSemiBold textBase18">{post.user.login}</span>

            <span className="date textBase14">{generatePostCreateDate(new Date(post.created_at))}</span>
          </div>
        </div>

        <Menu post={post} onPostDelete={onPostDelete} />
      </div>

      <div className="postBody">
        <p className="textBase18 text">
          {unfoldText
            ? post.text
            : (
              <>
                <span>{post.text.slice(0, 500)}</span>
                {' '}
                <span className="unfoldText" onClick={() => setUnfoldText(true)}>...читать далее</span>
              </>
            )}
        </p>

        <div className="filesContainer">
          <div className="imagesContainer">
            {(post.files || []).map((file) => {
              if (file.mime_type.startsWith('image')) {
                return <img src={file.url} alt="" loading="lazy" />;
              }

              return null;
            })}
          </div>
        </div>
      </div>
    </PostStyles>
  );
}

export default Post;
