import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import workWithResponse from '../../../../../helpers/workWithResponse';
import { Api } from '../../../../../api';
import MainLoader from '../../../../other/loaders/MainLoader';
import ErrorComponent from '../../../../other/error/ErrorComponent';
import RulesStyles from './RulesStyles';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { TApiTreasuresMapRulesAndPrizes, TRules } from '../../../../../types/treasureMap.types';
import TextareaAutosize from 'react-textarea-autosize';
import TextArea from '../../../../form/textArea/TextArea';
import Button from '../../../../buttons/button/Button';
import { notification } from '../../../../../helpers/notifications/toastify';

function Rules() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rulesAndPrizes, setRulesAndPrizes] = useState<Pick<TApiTreasuresMapRulesAndPrizes, 'terms'>>({ terms: '' });
  const [dataLoadingError, setDataLoadingError] = useState<boolean>(false);

  async function getPrizes() {
    setIsLoading(true);

    await workWithResponse(() => Api.getTreasuresMapGameData()).then(
      (res) => {
        if (res.data && !res.error && res.data.terms) {
          setRulesAndPrizes({ terms: res.data.terms });
        } else if (res.error && res.error === 'game not found') {
          setRulesAndPrizes({
            terms: '',
          });
        } else {
          setDataLoadingError(true);
        }
      },
    );

    setIsLoading(false);
  }

  useEffect(() => {
    getPrizes();
  }, []);

  const formik = useFormik({
    initialValues: {
      terms: '',
    },
    onSubmit: async (values) => {
      try {
        formik.setSubmitting(true);

        const response = await workWithResponse(() => Api.changeTreasuresMapPrizesAndRules({ ...rulesAndPrizes, terms: values.terms }));

        if (response.statusCode >= 200 && response.statusCode < 300) {
          setRulesAndPrizes({ ...rulesAndPrizes, terms: values.terms });
          notification.success('Правила успешно обновлены');
        }
      } catch (error: any) {
        notification.error('Произошла ошибка при обновление правил');
      } finally {
        formik.setSubmitting(false);
      }
    },
    validationSchema: Yup.object().shape({
      terms: Yup.string().required('Обязательное поле'),
    }),
  });

  useEffect(() => {
    if (rulesAndPrizes.terms) {
      formik.setFieldValue('terms', rulesAndPrizes.terms);
    }
  }, [rulesAndPrizes]);

  return (
    <RulesStyles>
      {dataLoadingError ? (
        <ErrorComponent />
      ) : (
        <>
          <div className="head">
            <h2 className="textSemiBold22">Правила</h2>
          </div>

          {isLoading ? (
            <div className="loaderContainer">
              <MainLoader />
            </div>
          ) : (
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <TextArea
                  max={1000}
                  value={formik.values.terms}
                  onChange={formik.handleChange}
                  name="terms"
                  className="rulesTextArea"
                />

                <div className="formButtonsWrapper">
                  <Button
                    text="Отменить"
                    onClick={() => formik.setFieldValue('terms', rulesAndPrizes.terms)}
                    type="button"
                    theme="black"
                  />

                  <Button
                    text="Сохранить"
                    onClick={formik.handleSubmit}
                    type="submit"
                  />
                </div>
              </form>
            </FormikProvider>
          )}
        </>
      )}
    </RulesStyles>
  );
}

export default Rules;
