import React, { Suspense, useEffect } from 'react';
import './App.css';
import {
  Navigate, Route, Routes, useNavigate,
} from 'react-router-dom';
import { useAppSelector } from './state/hooks';

import { RingLoader } from 'react-spinners';
import Users from './pages/users/Users';
import MainLayout from './components/layouts/mainLayout/MainLayout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './pages/login/Login';
import Posts from './pages/posts/Posts';
import workWithResponse from './helpers/workWithResponse';
import { Api } from './api';
import TreasuresMap from './pages/treasuresMap/TreasuresMap';
import CreatePoster from './pages/treasuresMap/createPoster/CreatePoster';
import CreatePrizes from './pages/treasuresMap/createPrizes/CreatePrizes';
import { useDispatch } from 'react-redux';
import { changeUser, setUser } from './state/slices/userSlice';

function App() {
  const { loggedIn, data: user } = useAppSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loggedIn) {
      workWithResponse(() => Api.getMyAccount()).then((res) => {
        if (res.statusCode >= 200 && res.statusCode < 300 && res.data) {
          if (res.data.id !== 1) {
            dispatch(changeUser(null));
          } else {
            dispatch(setUser(res.data));
          }
        } else {
          dispatch(changeUser(null));
        }
      });
    }
  }, [loggedIn]);

  return (
    <>
      <ToastContainer
        limit={1}
        style={{
          maxWidth: '500px',
          width: '100%',
          paddingLeft: '16px',
          paddingRight: '16px',
        }}
      />

      <Suspense
        fallback={(
          <div
            className="spinner"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100vw',
              height: '100vh',
            }}
          >
            <RingLoader
              color="#A21919"
              size={100}
            />
          </div>
        )}
      >
        <Routes>
          {!loggedIn && (
            <>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          )}

          {loggedIn && (
            <Route element={<MainLayout />}>
              <Route path="/users" element={<Users />} />
              <Route path="/posts" element={<Posts />} />
              <Route path="/games/treasures-map" element={<TreasuresMap />} />
              <Route path="/games/treasures-map/create-poster" element={<CreatePoster />} />
              <Route path="/games/treasures-map/prizes" element={<CreatePrizes />} />

              <Route path="*" element={<Navigate to="/users" />} />
            </Route>
          )}

          {/* {!loggedIn && ( */}
          {/*  <Route path="/login" element={<Login />} /> */}

          {/*  <Route path="*" element={<Navigate to="/login" />} /> */}
          {/* )} */}

        </Routes>
      </Suspense>
    </>
  );
}

export default App;
