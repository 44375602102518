import { ApiPoster, ApiPosterWithImageURL } from '../../types/treasureMap.types';

export function mapFromFileToFormData(file: File, width?: number, height?: number) {
  const formData = new FormData();

  formData.append('file', file);

  if (width) {
    formData.append('width', width.toString());
  }

  if (height) {
    formData.append('height', height.toString());
  }

  return formData;
}

export const mapFromApiPostersToApiPostersWithImageURL = (posters: ApiPoster[]): ApiPosterWithImageURL[] => posters.map((poster) => {
  const byteCharacters = atob(poster.data);
  const byteNumbers = Array.from(byteCharacters).map((char) => char.charCodeAt(0));
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'image/png' });

  return {
    ...poster,
    imageURL: URL.createObjectURL(blob),
  };
});
