export default (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_547_5714)">
      <path d="M6.86008 2.57347L1.21341 12.0001C1.09699 12.2018 1.03539 12.4303 1.03473 12.6631C1.03408 12.896 1.0944 13.1249 1.20969 13.3272C1.32498 13.5294 1.49123 13.698 1.69188 13.816C1.89254 13.9341 2.12061 13.9976 2.35341 14.0001H13.6467C13.8795 13.9976 14.1076 13.9341 14.3083 13.816C14.5089 13.698 14.6752 13.5294 14.7905 13.3272C14.9057 13.1249 14.9661 12.896 14.9654 12.6631C14.9648 12.4303 14.9032 12.2018 14.7867 12.0001L9.14008 2.57347C9.02123 2.37754 8.85389 2.21555 8.65421 2.10313C8.45452 1.9907 8.22923 1.93164 8.00008 1.93164C7.77092 1.93164 7.54563 1.9907 7.34594 2.10313C7.14626 2.21555 6.97892 2.37754 6.86008 2.57347Z" stroke="#FDFDFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.00006 6V8.66667" stroke="#FDFDFD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.00006 11.334H8.00673" stroke="#FDFDFD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_547_5714">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
