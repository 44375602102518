import React, {
  useState, ChangeEvent, useCallback, useRef,
} from 'react';
import { TextAreaStyles } from './TextAreaStyles';
import { ErrorMessage, useFormikContext } from 'formik';
import FormErrorMessage from '../formErrorMessage/FormErrorMessage';
import EmojiPicker from '../emojiPicker/EmojiPicker';
import { TEmojiItem } from '../../../types/emoji.types';

interface TextareaProps {
  name: string;
  value: string;
  onChange: any;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  label?: string;
  maxRows?: number;
  maxCols?: number;
  max?: number;
  className?: string;
  disabled?: boolean;
  resize?: boolean;
  showError?: boolean;
  enabledEmojis?: boolean;
}

const Textarea: React.FC<TextareaProps> = ({
  name,
  value,
  onChange: handleChange,
  onKeyDown,
  placeholder,
  label,
  maxRows,
  maxCols,
  max,
  className,
  disabled,
  resize = false,
  showError = true,
  enabledEmojis,
}) => {
  const { touched, errors } = useFormikContext<any>();
  const [isEmojiPickerOpen, setEmojiPickerOpen] = useState(false);

  const emojiPickerButtonRef = useRef<null | HTMLButtonElement>(null);

  const textareaId = label ? `${label.toLowerCase().replace(/ /g, '-')}-textarea` : '';
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const error = touched[name] && errors[name];

  const handleEmojiClick = useCallback((emoji: TEmojiItem) => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      const cursorPos = textarea.selectionStart;
      const textBefore = value.substring(0, cursorPos);
      const textAfter = value.substring(cursorPos);
      const newText = textBefore + emoji.emoji + textAfter;

      handleChange({
        target: {
          name,
          value: newText,
        },
      });

      setTimeout(() => {
        textarea.selectionStart = cursorPos + emoji.emoji.length;
        textarea.selectionEnd = cursorPos + emoji.emoji.length;
        textarea.focus();
      }, 0);
    }
  }, [handleChange, value]);

  return (
    <TextAreaStyles className={className}>
      {label && <label className="textSemiBold12 colorSecondary8" htmlFor={textareaId}>{label}</label>}
      <div className="textarea-container">
        <textarea
          name={name}
          id={textareaId}
          value={value}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          rows={maxRows}
          cols={maxCols}
          disabled={disabled}
          maxLength={max}
          className={error ? 'error' : disabled ? 'disabled' : ''}
          ref={textareaRef}
        />
        {enabledEmojis && (
          <>
            <button
              type="button"
              className="emoji-picker-toggle"
              onClick={() => setEmojiPickerOpen(!isEmojiPickerOpen)}
              ref={emojiPickerButtonRef}
            >
              😀
            </button>

            {isEmojiPickerOpen && emojiPickerButtonRef && (
              <EmojiPicker
                ref={emojiPickerButtonRef}
                onEmojiSelect={handleEmojiClick}
                handleClose={() => setEmojiPickerOpen(false)}
              />
            )}
          </>
        )}

      </div>
      {showError && (
        <div className="formErrorContainer">
          {touched[name] && errors[name] && <ErrorMessage name={name} component={FormErrorMessage} />}
        </div>
      )}
    </TextAreaStyles>
  );
};

export default Textarea;
