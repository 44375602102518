import styled from 'styled-components';

const PosterStyles = styled.div`
  position: relative;
  
  max-width: 280px;
  width: 100%;

  img {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }

  .coordinates {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  
  .deleteButton {
    position: absolute;
    top: 12px;
    right: 12px;
    
    display: grid;
    place-content: center;
    
    width: 32px;
    height: 32px;
    background: var(--red-2);
    border-radius: 100%;
  }
`;

export default PosterStyles;
