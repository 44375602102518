import styled from 'styled-components';
import colors from '../../../constants/colors';

export const TextAreaStyles = styled.div`
  display: grid;
  gap: 4px;
  
  .textarea-container {
    position: relative;

    .emoji-picker-toggle {
      font-size: 20px;
      
      top: 10px;
      right: 10px;
      position: absolute;
    }
  }
  
  textarea {
    font-size: 16px;
    color: var(--grey-1, #FEFEFE);
    border-radius: 4px;
    border: 1px solid #8C8C8C;
    background: var(--grey-7, #1C1C1C);
    box-sizing: border-box;
    padding: 10px 8px;
    transition: 0.3s all;
    font-family: 'Inter', sans-serif;
    outline: none;
    resize: none;
    width: 100%;
    height: 180px;
      
    &:hover {
      border: 1px solid ${colors.main3};
    }
      
    &:focus {
      border: 1px solid ${colors.main5};
    }
    
    &::placeholder {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      line-height: 22px;
      color: #979797;
      white-space: pre-wrap;
    }
      
    &.filled {
      border: 1px solid ${colors.main3};
    }
      
    &.disabled {
      border: 1px solid rgba(140, 140, 140, 0.35);
    }
  }
`;
