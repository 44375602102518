import React, { useRef, useState } from 'react';
import { HeaderStyles } from './HeaderStyles';
import { Link } from 'react-router-dom';

import MenuIcon from '../../../assets/icons/header/MenuIcon';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { changeUser } from '../../../state/slices/userSlice';
import Logo from '../../other/logo/Logo';
import DropDown from '../../other/dropDown/DropDown';
import UserIcon from '../../../assets/icons/header/UserIcon';
import LogOutIcon from '../../../assets/icons/header/LogOutIcon';
import { Api } from '../../../api';

function Header({ setSidebarOpen }: {setSidebarOpen(): void }) {
  const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);

  const userMenuButtonRef = useRef<any>();

  const dispatch = useAppDispatch();

  const { data: user } = useAppSelector((state) => state.user);

  const logout = () => {
    Api.logout();
    localStorage.removeItem('logged_in');
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expires_at');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_token_expires_at');
    dispatch(changeUser(null));
  };

  // useClickOutside({ current: (activeMenuItemIndex || activeMenuItemIndex === 0) && menuRef.current[activeMenuItemIndex] }, () => setActiveMenuItemIndex(null), { current: [...menuRef.current, backgroundRef.current] });

  return (
    <HeaderStyles className="boxShadow1">
      <div className="logoContainer">
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button type="button" onClick={setSidebarOpen} className="menuButton"><MenuIcon /></button>

        <Link to="/">
          <Logo />
        </Link>
      </div>

      <div className="userMenuButtonWrapper">
        <button ref={userMenuButtonRef} type="button" onClick={() => setUserMenuOpen(!userMenuOpen)} className="textSemiBold16 colorMain4 userMenuButton">
          {user?.file_url ? <img src={user.file_url} alt="Logo" /> : (
            <svg xmlns="http://www.w3.org/2000/svg" width="37" height="32" viewBox="0 0 37 32" fill="none">
              <path d="M19.2115 0.00269518C9.21735 -0.168052 0.976422 7.79559 0.805675 17.7898C0.708691 23.4565 3.23438 28.5495 7.25581 31.9365C7.51876 31.7021 7.80666 31.4974 8.11433 31.3259L13.5147 28.3802C13.8625 28.1904 14.1528 27.9104 14.3551 27.5697C14.5574 27.229 14.6641 26.8401 14.6642 26.4439V24.231C14.6642 24.231 13.079 22.335 12.4745 19.7008C12.2187 19.537 12.008 19.3117 11.8617 19.0455C11.7155 18.7794 11.6383 18.4807 11.6372 18.177V15.7551C11.6372 15.2224 11.8742 14.7464 12.2423 14.4131V10.9121C12.2423 10.9121 11.5231 5.46387 18.9014 5.46387C26.2798 5.46387 25.5606 10.9121 25.5606 10.9121V14.4131C25.9294 14.7464 26.1657 15.2224 26.1657 15.7551V18.177C26.1657 18.9911 25.6193 19.6762 24.8776 19.905C24.4664 21.197 23.8602 22.4187 23.08 23.5275C22.8812 23.8082 22.6954 24.0459 22.5329 24.231V26.4999C22.5329 27.3352 23.0048 28.0995 23.752 28.4724L29.5349 31.3635C29.8755 31.5372 30.1942 31.7509 30.4842 32C34.3841 28.7476 36.9036 23.8847 36.9972 18.4085C37.1693 8.41437 29.2064 0.173442 19.2115 0.00269518Z" fill="#A21919" />
            </svg>
          )}
        </button>

        {userMenuOpen && (
          <DropDown onClose={() => setUserMenuOpen(false)} isOpened={userMenuOpen} exclude={userMenuButtonRef}>
            <ul>
              {/* <li onClick={() => setUserMenuOpen(false)}> */}
              {/*  <Link to="/profile" className="textSemiBold12"> */}
              {/*    <UserIcon /> */}
              {/*    <span> */}
              {/*      Профіль */}
              {/*    </span> */}
              {/*  </Link> */}
              {/* </li> */}
              <li>
                <button
                  className="logout"
                  type="button"
                  onClick={() => {
                    logout();
                    setUserMenuOpen(false);
                  }}
                >
                  <LogOutIcon />
                  <span className="textSemiBold12">
                    Выйти
                  </span>
                </button>
              </li>
            </ul>
          </DropDown>
        )}
      </div>
    </HeaderStyles>
  );
}

export default Header;
