import styled from 'styled-components';

export const TableStyles = styled.div`
  width: 100%;
  
  overflow: auto;
  margin-bottom: -100px;
  padding-bottom: 100px;
  
  .cursor-pointer {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 18px;
  }

  .arrowDownActive {
    transform: rotate(180deg);
  }
  
  svg {
    &.active {
      transform: rotate(180deg);
    }
  }
  
  table {
    width: 100%;

    color: var(--White, #FDFDFD);

    border-collapse: separate;
    border-spacing: 0;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    background: var(--Cards2, #1C1C1C);

    thead {
      height: 52px;

      background: var(--Cards2, #1C1C1C);
      
      th {
        padding: 0 30px !important;
      }
      
      th {
        text-align: left;
        padding: 0 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #393636;
        border-top: 1px solid #393636;

        &:nth-child(1) {
          border-left: 1px solid #393636;
        }

        &:last-child {
          border-right: 1px solid #393636;
        }
      }
    }
    
    tbody {
      
      tr {
        transition: 0.3s ease all;

        &:hover {
          background: #2A2A2A;
        }
      }

      td {
        border-bottom: 1px solid #393636; /* Додайте стилі границь для комірок таблиці, якщо потрібно */

        &:nth-child(1) {
          border-left: 1px solid #393636;
        }

        &:last-child {
          border-right: 1px solid #393636;
        }
      }
      
      td {
        padding: 14px 30px;
        box-sizing: border-box;
      }
    }
  }
`;
