import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { TApiTreasuresMapRulesAndPrizes, TRules } from '../../../types/treasureMap.types';
import workWithResponse from '../../../helpers/workWithResponse';
import { Api } from '../../../api';
import ErrorComponent from '../../../components/other/error/ErrorComponent';
import MainLoader from '../../../components/other/loaders/MainLoader';
import InputField from '../../../components/form/input/InputField';
import Button from '../../../components/buttons/button/Button';
import { useNavigate } from 'react-router-dom';
import TextArea from '../../../components/form/textArea/TextArea';
import CreatePrizesStyles from './CreatePrizesStyles';
import { notification } from '../../../helpers/notifications/toastify';

function CreatePrizes() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataLoadingError, setDataLoadingError] = useState<boolean>(false);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: '',
      count: 1,
    },
    onSubmit: async (values) => {
      try {
        formik.setSubmitting(true);

        const response = await workWithResponse(() => Api.createPrize(values));

        if (response.statusCode >= 200 && response.statusCode < 300) {
          notification.success('Призы успешно добавлены');
          formik.resetForm();
        } else {
          console.log(response.error);
          throw new Error();
        }
      } catch (error) {
        notification.error('Произошла ошибка при добавлении призов');
      } finally {
        formik.setSubmitting(false);
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Обязательное поле'),
      count: Yup.number()
        .required('Обязательное поле'),
    }),
  });

  return (
    <CreatePrizesStyles>
      {dataLoadingError ? (
        <ErrorComponent />
      ) : (
        <>
          <div className="head">
            <h1 className="textBold28">Добавление приза</h1>
          </div>

          {isLoading ? (
            <div className="loaderContainer">
              <MainLoader />
            </div>
          ) : (
            <FormikProvider value={formik}>
              <form action="">
                <div className="fieldsWrapper">
                  <TextArea
                    max={1000}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    name="name"
                    className="rulesTextArea"
                    label="Описание приза"
                    placeholder="Введите описание приза"
                    enabledEmojis
                  />

                  <InputField
                    placeholder="Введите количество"
                    name="count"
                    onChange={formik.handleChange}
                    value={formik.values.count}
                    label="Количество"
                    type="number"
                    min={1}
                    max={1000}
                  />
                </div>

                <div className="formButtonsWrapper">
                  <Button
                    onClick={formik.isSubmitting ? () => null : () => navigate('/games/treasures-map?tab=prizes')}
                    text="Закрыть"
                    theme="red"
                    disabled={formik.isSubmitting}
                    isLoading={formik.isSubmitting}
                  />

                  <Button
                    onClick={formik.handleSubmit}
                    text="Сохранить"
                    theme="black"
                    disabled={formik.isSubmitting}
                    isLoading={formik.isSubmitting}
                  />
                </div>
              </form>
            </FormikProvider>
          )}
        </>
      )}
    </CreatePrizesStyles>
  );
}

export default CreatePrizes;
