import styled from 'styled-components';

const ChangeRatingModalStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .modalHead {
    display: flex;
    flex-direction: column;
    gap: 8px;

    text-align: center;

    span {
      color: #D9D9D9;
    }

    .title {
      font-family: Gilroy-Bold;
      font-size: 22px;
      font-weight: 400;
      line-height: 34px;
      letter-spacing: 0.44px;

      @media (min-width: 768px) {
        font-size: 28px;
        line-height: 37px;
        letter-spacing: 0.56px;
      }
    }
  }

  .buttonsWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 12px;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    .button {
      flex: 1 1 0px;
    }
  }
`;

export default ChangeRatingModalStyles;
