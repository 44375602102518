import styled from 'styled-components';

const RulesStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  
  max-width: 1200px;
  
  form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    
    .rulesTextArea {
      textarea {
        min-height: 300px;
      }
    }
  }
`;

export default RulesStyles;
