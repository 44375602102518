import { changeUser } from '../state/slices/userSlice';

export const logout = async () => {
  const { store } = await import('../state/store');
  store.dispatch(changeUser(null));
  localStorage.removeItem('access_token');
  localStorage.removeItem('access_token_expired_at');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('refresh_token_expired_at');
  localStorage.removeItem('chat_token');
  localStorage.removeItem('chat_token_expired_at');
  localStorage.removeItem('refresh_chat_token_expired_at');
  localStorage.removeItem('chat_refresh_token');
};
