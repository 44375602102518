import styled from 'styled-components';

export const MainLayoutStyles = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;

  header {
    //position: absolute;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    padding: 0 16px 0 16px;
    box-sizing: border-box;

    background: var(--Cards, #171616);
    border-bottom: 1px solid var(--Stroke2, #393636);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    @media (min-width: 768px) {
      padding: 0 39px 0 39px;
    }

    @media (min-width: 1201px) {
      padding: 0 74px 0 50px;
    }
  }

  main {
    flex-grow: 1;
    display: flex;
    width: 100%;
    min-height: 100vh;
    padding: 76px 16px 16px;
    box-sizing: border-box;
    background: var(--Cards, #171616);

    @media (min-width: 768px) {
      padding: 84px 39px 39px;
    }

    @media (min-width: 1201px) {
      padding: 100px 74px 100px 354px;
    }
  }
`;
