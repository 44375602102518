import React, { CSSProperties, useMemo, useState } from 'react';
import { InputFieldStyles } from './InputFieldStyles';
import ErrorIcon from '../../../assets/icons/formElements/ErrorIcon';
import CrossedEyeIcon from '../../../assets/icons/formElements/CrossedEyeIcon';
import EyeIcon from '../../../assets/icons/formElements/EyeIcon';
import { ErrorMessage, useFormikContext } from 'formik';
import FormErrorMessage from '../formErrorMessage/FormErrorMessage';

interface InputFieldProps {
  name: string;
  value: string | number | undefined;
  min?: number;
  max?: number;
  placeholder: string;
  // eslint-disable-next-line no-unused-vars
  onChange: any;
  showError?: boolean;
  extraBlockStyles?: CSSProperties;
  label?: string;
  onKeyUp?: () => void;
  disabled?: boolean;
  type?: string;
  required?: boolean;
  children?: any;
  filledStyle?: boolean;
  replaceValueByStars?: boolean;
  autoComplete?: string | undefined;
}

export default function InputField({
  name,
  placeholder,
  label,
  showError = true,
  value,
  min,
  max,
  onChange,
  onKeyUp,
  extraBlockStyles,
  disabled,
  type,
  required,
  children,
  filledStyle,
  replaceValueByStars,
  autoComplete,
}: InputFieldProps) {
  const { touched, errors } = useFormikContext<any>();
  const [isFilled, setIsFilled] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const valueReplacedByStars = useMemo(() => {
    if (replaceValueByStars && typeof value === 'string') {
      return value.replace(/./g, '*');
    }
    return undefined;
  }, [value]);

  const error = touched[name] && errors[name];

  return (
    <InputFieldStyles style={extraBlockStyles}>
      <label htmlFor={name}>
        {label && (
          <span className="textSemiBold12 colorSecondary8 label">
            {label}
            {required && <span className="required"> *</span>}
          </span>
        )}
        <div className="inputWrap">
          <div className="inputContainer">
            <input
              id={name}
              type={type === 'password' && showPassword ? 'text' : type || 'text'}
              min={type === 'number' ? min : undefined}
              max={type === 'number' ? max : undefined}
              value={valueReplacedByStars || value}
              onChange={onChange}
              autoComplete={autoComplete}
              onBlur={() => {
                if (filledStyle && value) {
                  setIsFilled(true);
                }
              }}
              onKeyUp={onKeyUp}
              placeholder={placeholder}
              disabled={disabled}
              className={
                error ? 'error'
                  : isFilled && filledStyle ? 'filled'
                    : disabled ? 'disabled' : ''
              }
            />
            {type === 'password' && (
              <button type="button" className="showPasswordButton" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <EyeIcon /> : <CrossedEyeIcon />}
              </button>
            )}
            {showError && (
              <div className="formErrorContainer">
                {touched[name] && errors[name] && <ErrorMessage name={name} component={FormErrorMessage} />}
              </div>
            )}
          </div>
          <div className="childrenContainer">
            {children}
          </div>
        </div>
      </label>
    </InputFieldStyles>
  );
}
