import TabsHandlerStyles from './TabsHandlerStyles';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

type TabsHandlerProps<TabNames> = {
  buttons: {
    value: TabNames;
    name: string;
  }[];
  selectedTab: TabNames;
  handleChangeActiveTab(name: TabNames): void;
}

function TabsHandler<TabNames>({ handleChangeActiveTab, buttons, selectedTab }: TabsHandlerProps<TabNames>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tabWidth, setTabWidth] = useState(0);
  const [tabPosition, setTabPosition] = useState(0);
  const tabRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (tabRef.current) {
      setTabWidth(tabRef.current.offsetWidth);
      setTabPosition(tabRef.current.offsetLeft);
    }
  }, [selectedTab, tabRef.current]);

  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);

    newParams.set('tab', selectedTab as string);

    setSearchParams(newParams);
  }, [selectedTab]);

  return (
    <TabsHandlerStyles>
      <div className="buttonsWrapper">
        {buttons.map((button) => (
          <button
            ref={selectedTab === button.value ? tabRef : null}
            className={classNames({ active: selectedTab === button.value })}
            key={button.name}
            type="button"
            onClick={() => handleChangeActiveTab(button.value)}
          >
            {button.name}
          </button>
        ))}
        <div
          className="slider"
          style={{
            width: tabWidth,
            transform: `translateX(${tabPosition}px)`,
          }}
        />
      </div>
    </TabsHandlerStyles>
  );
}

export default TabsHandler;
