import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuStyles } from './MenuStyles';
import MenuIcon from '../../../../../../assets/icons/header/MenuIcon';
import DropDown from '../../../../../other/dropDown/DropDown';
import DeleteIcon from '../../../../../../assets/icons/table/DeleteIcon';
import { TApiPrize } from '../../../../../../types/treasureMap.types';
import DeleteModal from './deleteModal/DeleteModal';

interface MenuProps {
  prize: TApiPrize;
  onPrizeDelete(prize: TApiPrize): void;
}

function Menu({
  prize, onPrizeDelete,
}: MenuProps) {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [activeModalName, setActiveModalName] = useState<null | 'deleting'>(null);

  const handleCloseModal = useCallback(() => {
    setActiveModalName(null);
  }, []);

  const navigate = useNavigate();

  const menuRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      {activeModalName === 'deleting' && (
        <DeleteModal
          onPrizeDelete={onPrizeDelete}
          prize={prize}
          handleClose={handleCloseModal}
        />
      )}

      <MenuStyles ref={menuRef}>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            setMenuOpen(!menuOpen);
          }}
        >
          <MenuIcon />
        </button>

        <DropDown onClose={() => setMenuOpen(false)} isOpened={menuOpen} exclude={menuRef}>
          <button type="button" onClick={() => setActiveModalName('deleting')}>
            {DeleteIcon}
            {' '}
            Удалить
          </button>
        </DropDown>
      </MenuStyles>
    </>
  );
}

export default Menu;
