import styled from 'styled-components';

const TabsHandlerStyles = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  position: relative;
  width: 100%;

  .buttonsWrapper {
    display: flex;
    position: relative;
    align-items: center;
    gap: 30px;
    overflow: auto;
  }

  button {
    display: inline-block;
    color: var(--white);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    position: relative;
    padding-bottom: 6px;
    white-space: nowrap;

    &.active {
      color: var(--red-2);
      font-weight: 600;
    }
  }

  .slider {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: var(--red-2);
    transition: transform 0.3s ease, width 0.3s ease;
  }
`;

export default TabsHandlerStyles;
