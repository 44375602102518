import { FormikProvider, useFormik } from 'formik';
import { loginUser } from '../../../../state/slices/userSlice';
import * as Yup from 'yup';
import { notification } from '../../../../helpers/notifications/toastify';
import Modal from '../../../modal/Modal';
import { ApiUser } from '../../../../types/user.types';
import ChangeRatingModalStyles from './ChangeRatingModalStyles';
import React from 'react';
import Button from '../../../buttons/button/Button';
import InputField from '../../../form/input/InputField';
import workWithResponse from '../../../../helpers/workWithResponse';
import { Api } from '../../../../api';

interface ChangeRatingModalProps {
  onClose(): void;
  user: ApiUser;
  onSuccess(updatedUser: ApiUser): void;
}

function ChangeRatingModal({ user, onSuccess, onClose }: ChangeRatingModalProps) {
  const formik = useFormik({
    initialValues: {
      rating: user.rating,
    },
    onSubmit: async (values) => {
      if (values) {
        formik.setSubmitting(true);

        try {
          await workWithResponse(() => Api.changeUserData({ ...user, rating: values.rating })).then((res) => {
            if (res.error) {
              notification.error(res.error);
            } else {
              onSuccess({ ...user, rating: values.rating });
              notification.success('Рейтинг изменен');
              formik.setSubmitting(false);
              onClose();
            }
          });
        } catch (e) {
          notification.error('Произошла ошибка');
        } finally {
          formik.setSubmitting(false);
        }
      }
    },
    validationSchema: Yup.object().shape({
      rating: Yup.number().min(1, 'Минимальный рейтинг 1').required('Введите рейтинг'),
    }),
  });

  return (
    <Modal onClose={formik.isSubmitting ? () => null : onClose}>
      <ChangeRatingModalStyles>
        <div className="modalHead">
          <span className="textBold28">
            Изменение рейтинга пользователя
            {' '}
            {`"${user.login}"`}
            {' '}
          </span>

          <span className="textBase18">
            Текущий рейтинг
            {' '}
            {user.rating}
          </span>
        </div>

        <FormikProvider value={formik}>
          <div className="fieldsWrapper">
            <InputField
              placeholder="Введите рейтинг"
              name="rating"
              onChange={formik.handleChange}
              value={formik.values.rating}
              label="Рейтинг"
              type="number"
            />
          </div>

          <div className="buttonsWrapper">
            <Button
              onClick={formik.isSubmitting ? () => null : onClose}
              text="Закрыть"
              theme="red"
              disabled={formik.isSubmitting}
              isLoading={formik.isSubmitting}
            />

            <Button
              onClick={formik.handleSubmit}
              text="Сохранить"
              theme="black"
              disabled={formik.isSubmitting}
              isLoading={formik.isSubmitting}
            />
          </div>
        </FormikProvider>
      </ChangeRatingModalStyles>
    </Modal>
  );
}

export default ChangeRatingModal;
