import React, {
  ChangeEvent, useEffect, useMemo, useState,
} from 'react';
import { useWindowSize } from 'usehooks-ts';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import workWithResponse from '../../../../../helpers/workWithResponse';
import { Api } from '../../../../../api';
import { Table } from '../../../../table/Table';
import MainLoader from '../../../../other/loaders/MainLoader';
import ErrorComponent from '../../../../other/error/ErrorComponent';
import ModalConfirmDecline from '../../../../modals/modalConfirmDecline/ModalConfirmDecline';
import PrizesStyles from './PrizesStyles';
import { notification } from '../../../../../helpers/notifications/toastify';
import { ColumnDef } from '@tanstack/react-table';
import Pagination from '../../../../table/pagination/Pagination';
import ListMobile from './listMobile/ListMobile';
import { TApiPrize, TPrize } from '../../../../../types/treasureMap.types';
import Menu from './menu/Menu';

function Prizes() {
  const [params, setParams] = useSearchParams();

  const [tableData, setTableData] = useState<TApiPrize[]>([]);
  const [activePage, setActivePage] = useState<number>(() => {
    const pageFromParams = params.get('page');

    if (pageFromParams && !Number.isNaN(+pageFromParams) && +pageFromParams > 0) {
      return +pageFromParams;
    }

    return 1;
  });
  const [total, setTotal] = useState<number>(0);
  const [activeModalName, setActiveModalName] = useState<'deleting'>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataLoadingError, setDataLoadingError] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const windowSize = useWindowSize();

  async function getPrizes() {
    setIsLoading(true);

    await workWithResponse(() => Api.getTreasuresMapPrizes()).then((res) => {
      if (res.data && !res.error) {
        setTableData(res.data);
        setTotal(res.data?.length || 0);
      } else {
        setDataLoadingError(true);
      }
    });

    setIsLoading(false);
  }

  useEffect(() => {
    // const searchParams = new URLSearchParams();
    // searchParams.set('page', String(activePage));

    // setParams(searchParams);

    getPrizes();
  }, []);

  const cols = useMemo<ColumnDef<TApiPrize>[]>(
    () => [
      {
        header: 'Описание',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => (
          <span className="textBase18">{row.cell.row.original.name}</span>
        ),
        accessorKey: 'name',
        enableSorting: false,
      },
      {
        header: 'Количество',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => (
          <span className="textBase18">{row.cell.row.original.count}</span>
        ),
        accessorKey: 'count',
        enableSorting: false,
      },
      {
        header: 'Меню',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (row) => (
          <Menu
            prize={row.cell.row.original}
            onPrizeDelete={getPrizes}
          />
        ),
        accessorKey: 'menu',
        enableSorting: false,
        size: 70,
      },
    ],
    [tableData],
  );

  return (
    <PrizesStyles>
      {dataLoadingError ? <ErrorComponent /> : (
        <>
          <div className="head">
            <h2 className="textSemiBold22">Призы</h2>
          </div>

          {isLoading ? <div className="loaderContainer"><MainLoader /></div> : (
            <div className="tableWrapper">
              {
                tableData && windowSize.width > 600 && (
                  <Table
                    data={tableData}
                    columns={cols}
                    withSorting={false}
                  />
                )
              }

              {
                windowSize.width <= 600 && (
                  <ListMobile
                    prizes={tableData}
                    onPrizeDelete={getPrizes}
                  />
                )
               }
              {/* {total > 0 && ( */}
              {/*  <Pagination */}
              {/*    activePage={activePage} */}
              {/*    total={total} */}
              {/*    setActivePage={(pageNumber) => setActivePage(pageNumber)} */}
              {/*    extraClasses={windowSize.width <= 600 ? 'paginationMobile' : undefined} */}
              {/*  /> */}
              {/* )} */}
            </div>
          )}
        </>
      )}

    </PrizesStyles>
  );
}

export default Prizes;
